import { amountLength } from "../utils";

export const validationFunction = (amount, sourceAccount, targetAccount) => {
  let errors = {};
  if (!targetAccount) errors.targetAccount = true;
  if (!sourceAccount) errors.sourceAccount = true;
  if (!amount || amount.length > amountLength) errors.amount = true;
  if (sourceAccount && targetAccount && sourceAccount.id === targetAccount.id) {
    errors.targetAccount = true;
    errors.sourceAccount = true;
  }
  return errors;
};

export const headers = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Category" },
  { id: 3, name: "Amount" },
  // { id: 7, name: "Created On" },
  // { id: 8, name: "Created By" },
  // { id: 9, name: "Action" },
];

export function getIsProfit(data, isApprovedBal) {
  if (!data?.leftAccountGroupFetchResponseList) return;
  if (!data?.rightAccountGroupFetchResponseList) return;
  if (isApprovedBal) {
    const totalExpense = data?.leftAccountGroupFetchResponseList.reduce(
      (total, child) => total + child?.approvedAccountGroupCurrentBalance,
      0
    );
    const totalRevenue = data?.rightAccountGroupFetchResponseList.reduce(
      (total, child) => total + child?.approvedAccountGroupCurrentBalance,
      0
    );
    return totalExpense > totalRevenue
      ? { isRevenueLoss: false, isExpenseLoss: true }
      : { isRevenueLoss: true, isExpenseLoss: false };
  } else {
    const totalExpense = data?.leftAccountGroupFetchResponseList.reduce(
      (total, child) => total + child?.accountGroupCurrentBalance,
      0
    );
    const totalRevenue = data?.rightAccountGroupFetchResponseList.reduce(
      (total, child) => total + child?.accountGroupCurrentBalance,
      0
    );
    return totalExpense > totalRevenue
      ? { isRevenueLoss: false, isExpenseLoss: true }
      : { isRevenueLoss: true, isExpenseLoss: false };
  }
}
export function getTotalGoupBalance(data, isApprovedBal) {
  if (!data) return;
  if (isApprovedBal) {
    return data.reduce(
      (total, child) => total + child.approvedAccountGroupCurrentBalance,
      0
    );
  } else {
    return data.reduce(
      (total, child) => total + child.accountGroupCurrentBalance,
      0
    );
  }
}
export function getEqualBalance(profitBal, lossBal) {
  if (profitBal == null || lossBal == null) return;
  const result = profitBal - lossBal;
  return lossBal + result;
}


////////////////////////// 2nd for p And l

export function getIsProfit2(data, isApprovedBal) {
  console.log(data)
  if (!data?.leftAccountGroupFetchResponseList) return;
  if (!data?.rightAccountGroupFetchResponseList) return;
  if (isApprovedBal) {
    const totalExpense = data?.leftAccountGroupFetchResponseList.reduce(
      (total, child) => total + Math.abs(child?.approvedAccountGroupCurrentBalance),
      0
    );
    const totalRevenue = data?.rightAccountGroupFetchResponseList.reduce(
      (total, child) => total + Math.abs(child?.approvedAccountGroupCurrentBalance),
      0
    );
    return totalExpense > totalRevenue
      ? { isRevenueLoss: false, isExpenseLoss: true }
      : { isRevenueLoss: true, isExpenseLoss: false };
  } else {
    const totalExpense = data?.leftAccountGroupFetchResponseList.reduce(
      (total, child) => total + Math.abs(child?.accountGroupCurrentBalance),
      0
    );
    const totalRevenue = data?.rightAccountGroupFetchResponseList.reduce(
      (total, child) => total + Math.abs(child?.accountGroupCurrentBalance),
      0
    );
    return totalExpense > totalRevenue
      ? { isRevenueLoss: false, isExpenseLoss: true }
      : { isRevenueLoss: true, isExpenseLoss: false };
  }
}
export function getTotalGoupBalance2(data, isApprovedBal) {
  if (!data) return;
  if (isApprovedBal) {
    return data.reduce(
      (total, child) => total + child.approvedAccountGroupCurrentBalance,
      0
    );
  } else {
    return data.reduce(
      (total, child) => total + child.accountGroupCurrentBalance,
      0
    );
  }
}
export function getEqualBalance2(profitBal, lossBal) {
  if (profitBal == null || lossBal == null) return;
  const result = profitBal - lossBal;
  return lossBal + result;
}
