import { companyConfigGet } from "../config";
import { userService } from "../userService";

export const getVouchersData = async (masterConfigIds, siteId) => {
  const companyId = localStorage.getItem("companyId");
  try {
    const payload = {
      companyId,
      masterConfigIds,
    };
    const res = await userService.postData(
      companyConfigGet,
      payload,
      null,
      true
    );
    if (res && res.data && res.data.status === 200) {
      const vouchersData = res.data.data;
      let obj = {};
      for (let i = 0; i < vouchersData.length; i++) {
        const ele = vouchersData[i];
        if (masterConfigIds.includes(ele?.masterConfigId)) {
          // obj[ele?.masterConfig?.configurationKey] =
          //   ele?.masterConfig?.defaultValue;

          obj[ele?.masterConfig?.configurationKey] = {
            value: ele?.defaultValue, // Set the default value
            // value: ele?.masterConfig?.defaultValue, // Set the default value
            site: {}, // Initialize the site as an empty object
          };

          const siteWise =
            ele?.siteConfigurations?.length > 0 &&
            ele?.siteConfigurations?.find((ele) => ele?.siteId == siteId);

          if (ele?.isDiffForSite === true && siteWise) {
            obj[ele?.masterConfig?.configurationKey].site = { ...siteWise };
          }
          // if (vouchersData?.isDiffForSite && siteWise) {
          //   obj.site = siteWise;
          // }
        }
      }
      return obj;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
