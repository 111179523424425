import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Pagination,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  Autocomplete,
  TextField,
  Collapse,
  Divider,
  Button,
  TableRow,
  TableCell,
  InputAdornment,
  Box,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FcInfo } from "react-icons/fc";
import { getAmountColor, headers } from "./util";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import {
  checkPermissionExist,
  DataStatus,
  heightCalculate,
  updateNestedItem,
  zeroBalanceReportFilter,
} from "../utils/index";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { pageSizes } from "../Account/util";
import { StyledTableCell, StyledTableRow } from "../utils/index";
// import Color from "color";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaDollarSign, FaFontAwesome, FaRupeeSign } from "react-icons/fa";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import AmountInfo from "../CommonComps/AmountInfo";
import { getEncryptPayload } from "../Network_Utility";
import {
  reportChildListUrl,
  trialBalanceUrl,
  updateReportbalance,
} from "../config";
import { userService } from "../userService";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { TbWorldSearch } from "react-icons/tb";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ColorInfo from "../CommonComps/ColorInfo";
import MySwitch from "../CommonComps/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { Editedtext } from "../CommonComps/TextTemplate";
import { themecolor } from "../Navigation";
import { getEqualBalance, getTotalGoupBalance } from "../Profit_And_Loss/util";

// function getColorForLevel(level) {
//   // Define a color scheme based on levels
//   const colorSchemes = [
//     "#DDEEFF", // Dark Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//   ];

//   // Use modulo to cycle through colors
//   return colorSchemes[level % colorSchemes.length];
// }

function AccountsRow({
  paddingLeft,
  val,
  getApprovedBal,
  showOpeningBalance,
  errorAlert,
  navigateToLedger,
  rowData,
  isClosingBalanceUpdateable,
  getAccountsList,
  childRow,
  balanceStatus,
  getBalanceSheetData,
  successAlert,
  endPoints,
}) {
  const [updatedBalance, setUpdatedBalance] = useState("");
  const [isUpdateBalance, setIsUpdateBalance] = useState(false);
  const [isUpdateBalanceLoading, setIsUpdateBalanceLoading] = useState(false);
  const [balanceType, setBalanceType] = useState("");

  const handleUpdateBalance = async (row, key) => {
    setIsUpdateBalance(false);
    setIsUpdateBalanceLoading(true);
    const payload = {
      id: row.accountId,
      balanceType: balanceType === "CR" ? "credit" : "debit",
      closingBalance: +updatedBalance,
      financialYearId: 5,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const response = await userService.postData(
        updateReportbalance,
        encryptedPayload
      );

      if (response && response.data && response.data.status === 200) {
        setTimeout(() => {
          getBalanceSheetData();
          setIsUpdateBalanceLoading(false);
          setIsUpdateBalance(false);
          successAlert(response.data?.message || "Success!!");
        }, 1000);
      } else {
        // Handle API error
        setIsUpdateBalanceLoading(false);
        setIsUpdateBalance(false);
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      setIsUpdateBalanceLoading(false);
      setIsUpdateBalance(false);
      // Handle API call error
      console.error("API Call Error:", error);
    }
    // setIsUpdateBalance(false);
    // setRowData({
    //   ...rowData,
    //   [key]: -123,
    // });
  };
  return (
    <>
      <StyledTableCell
        style={{
          paddingLeft: paddingLeft,
          minWidth: "300px",
          width: "600px",
        }}
      >
        <Typography variant="body2" className="table_value">
          <IconButton disabled>
            <KeyboardArrowUpIcon
              style={{
                visibility: "hidden",
              }}
            />
          </IconButton>
          <span
            style={{
              color: val?.isAccount && "blue",
            }}
            onClick={() => navigateToLedger(val)}
          >
            <Editedtext num="50" text={val?.name} />
            {/* {val?.name} */}
          </span>
        </Typography>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          padding: "5px 0px !important",
          width: "40%",
        }}
      >
        {!getApprovedBal ? (
          <span
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "10px",
              // paddingRight:
              //   row?.amount == undefined || row?.amount == 0 ? "22px" : "0px",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: val?.accountGroupCurrentBalance < 0 ? "green" : "red",
              }}
            >
              {(val?.openingBalance !== null &&
                val?.openingBalance !== undefined) ||
              (val?.accountGroupCurrentBalance !== null &&
                val?.accountGroupCurrentBalance !== undefined) ? (
                <>
                  {showOpeningBalance && (
                    <span
                      style={{
                        color: "#2d269f",
                        padding: "0px 2px",
                      }}
                    >
                      <AmountInfo amount={val?.openingBalance} />
                      {" | "}{" "}
                    </span>
                  )}
                  {!isUpdateBalance && !isUpdateBalanceLoading && (
                    <AmountInfo amount={val?.accountGroupCurrentBalance} />
                  )}
                  {isUpdateBalanceLoading && <CircularProgress size="30px" />}
                  {isUpdateBalance && (
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        id="standard-basic"
                        sx={{
                          width: "110px",
                          "& .MuiInputBase-input": {
                            color: balanceType === "CR" ? "red" : "green",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor:
                              balanceType === "CR" ? "red" : "green",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor:
                              balanceType === "CR" ? "red" : "green",
                          },
                        }}
                        variant="standard"
                        size="small"
                        value={updatedBalance}
                        onChange={(e) => {
                          setUpdatedBalance(e.target.value);
                        }}
                      />
                      <FormControl
                        variant="standard"
                        size="small"
                        sx={{
                          width: "50px",
                          "& .MuiInput-underline:before": {
                            borderBottomColor:
                              balanceType === "CR" ? "red" : "green",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor:
                              balanceType === "CR" ? "red" : "green",
                          },
                        }}
                      >
                        <Select
                          value={balanceType}
                          onChange={(e) => setBalanceType(e.target.value)}
                          sx={{
                            color: balanceType === "CR" ? "red" : "green",
                            "& .MuiSelect-icon": {
                              color: balanceType === "CR" ? "red" : "green",
                            },
                          }}
                        >
                          <MenuItem
                            value="CR"
                            sx={{ color: "red", fontWeight: "bold" }}
                          >
                            CR
                          </MenuItem>
                          <MenuItem
                            value="DR"
                            sx={{ color: "green", fontWeight: "bold" }}
                          >
                            DR
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  {checkPermissionExist(endPoints, updateReportbalance) &&
                    isClosingBalanceUpdateable &&
                    (!isUpdateBalance ? (
                      <IconButton
                        onClick={() => {
                          setIsUpdateBalance(true);
                          setBalanceType(
                            val.accountGroupCurrentBalance <= 0 ? "DR" : "CR"
                          );
                          setUpdatedBalance(
                            Math.abs(val?.accountGroupCurrentBalance)
                          );
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          handleUpdateBalance(val, "accountGroupCurrentBalance")
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                    ))}
                </>
              ) : (
                "-"
              )}
            </span>
          </span>
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",

              // paddingRight:
              //   val?.amount == undefined || val?.amount == 0 ? "22px" : "0px",
            }}
          >
            {" "}
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "10px",
                color: val?.approvedAccountGroupCurrentBalance
                  ? val?.approvedAccountGroupCurrentBalance == 0
                    ? "black"
                    : val?.accountGroupCurrentBalance < 0
                    ? "green"
                    : "red"
                  : val?.approvedClosingBalance
                  ? val?.approvedClosingBalance - val?.approvedOpeningBalance ==
                    0
                    ? "black"
                    : val.accountGroupCurrentBalance < 0
                    ? "green"
                    : "red"
                  : "green",
                // color:
                //   val?.approvedAccountGroupCurrentBalance -
                //     val?.approvedOpeningBalance ==
                //   0
                //     ? "black"
                //     : val?.approvedAccountGroupCurrentBalance -
                //         val?.approvedOpeningBalance >
                //       0
                //     ? "red"
                //     : "green",
              }}
            >
              {/* {val?.approvedOpeningBalance ||
      val?.approvedAccountGroupCurrentBalance ? (
        <AmountInfo
          amount={
            val?.approvedAccountGroupCurrentBalance -
            val?.approvedOpeningBalance
          }
        />
      ) : (
        "-"
      )} */}

              {val?.approvedAccountGroupCurrentBalance !== null ? (
                val?.approvedOpeningBalance !== null ||
                val?.approvedAccountGroupCurrentBalance !== null ? (
                  <>
                    {showOpeningBalance && (
                      <span
                        style={{
                          color: "#2d269f",
                          padding: "0px 2px",
                        }}
                      >
                        <AmountInfo amount={val?.openingBalance || 0} />
                        {" | "}{" "}
                      </span>
                    )}
                    {/* <AmountInfo
                      amount={val?.approvedAccountGroupCurrentBalance}
                    /> */}
                    {!isUpdateBalance && !isUpdateBalanceLoading && (
                      <AmountInfo
                        amount={val?.approvedAccountGroupCurrentBalance}
                      />
                    )}
                    {isUpdateBalanceLoading && <CircularProgress size="30px" />}
                    {isUpdateBalance && (
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          id="standard-basic"
                          sx={{
                            width: "110px",
                            "& .MuiInputBase-input": {
                              color: balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                          }}
                          variant="standard"
                          size="small"
                          value={updatedBalance}
                          onChange={(e) => {
                            setUpdatedBalance(e.target.value);
                          }}
                        />
                        <FormControl
                          variant="standard"
                          size="small"
                          sx={{
                            width: "50px",
                            "& .MuiInput-underline:before": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                          }}
                        >
                          <Select
                            value={balanceType}
                            onChange={(e) => setBalanceType(e.target.value)}
                            sx={{
                              color: balanceType === "CR" ? "red" : "green",
                              "& .MuiSelect-icon": {
                                color: balanceType === "CR" ? "red" : "green",
                              },
                            }}
                          >
                            <MenuItem
                              value="CR"
                              sx={{ color: "red", fontWeight: "bold" }}
                            >
                              CR
                            </MenuItem>
                            <MenuItem
                              value="DR"
                              sx={{ color: "green", fontWeight: "bold" }}
                            >
                              DR
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {checkPermissionExist(endPoints, updateReportbalance) &&
                      isClosingBalanceUpdateable &&
                      (!isUpdateBalance ? (
                        <IconButton
                          onClick={() => {
                            setIsUpdateBalance(true);
                            setBalanceType(
                              val.approvedAccountGroupCurrentBalance <= 0
                                ? "DR"
                                : "CR"
                            );
                            setUpdatedBalance(
                              Math.abs(val?.approvedAccountGroupCurrentBalance)
                            );
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleUpdateBalance(val)}>
                          <CheckIcon />
                        </IconButton>
                      ))}
                  </>
                ) : (
                  "-"
                )
              ) : val?.approvedClosingBalance !== null ? (
                val?.approvedOpeningBalance !== null ||
                val?.approvedClosingBalance !== null ? (
                  <>
                    {showOpeningBalance && (
                      <span
                        style={{
                          color: "#2d269f",
                          padding: "0px 2px",
                        }}
                      >
                        <AmountInfo amount={val?.openingBalance || 0} />
                        {" | "}{" "}
                      </span>
                    )}
                    {/* <AmountInfo amount={val?.approvedClosingBalance} /> */}
                    {!isUpdateBalance && !isUpdateBalanceLoading && (
                      <AmountInfo amount={val?.approvedClosingBalance} />
                    )}
                    {isUpdateBalanceLoading && <CircularProgress size="30px" />}
                    {isUpdateBalance && (
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          id="standard-basic"
                          sx={{
                            width: "110px",
                            "& .MuiInputBase-input": {
                              color: balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                          }}
                          variant="standard"
                          size="small"
                          value={updatedBalance}
                          onChange={(e) => {
                            setUpdatedBalance(e.target.value);
                          }}
                        />
                        <FormControl
                          variant="standard"
                          size="small"
                          sx={{
                            width: "50px",
                            "& .MuiInput-underline:before": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor:
                                balanceType === "CR" ? "red" : "green",
                            },
                          }}
                        >
                          <Select
                            value={balanceType}
                            onChange={(e) => setBalanceType(e.target.value)}
                            sx={{
                              color: balanceType === "CR" ? "red" : "green",
                              "& .MuiSelect-icon": {
                                color: balanceType === "CR" ? "red" : "green",
                              },
                            }}
                          >
                            <MenuItem
                              value="CR"
                              sx={{ color: "red", fontWeight: "bold" }}
                            >
                              CR
                            </MenuItem>
                            <MenuItem
                              value="DR"
                              sx={{ color: "green", fontWeight: "bold" }}
                            >
                              DR
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {checkPermissionExist(endPoints, updateReportbalance) &&
                      isClosingBalanceUpdateable &&
                      (!isUpdateBalance ? (
                        <IconButton
                          onClick={() => {
                            setIsUpdateBalance(true);
                            setBalanceType(
                              val.approvedClosingBalance <= 0 ? "DR" : "CR"
                            );
                            setUpdatedBalance(
                              Math.abs(val?.approvedClosingBalance)
                            );
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleUpdateBalance(val)}>
                          <CheckIcon />
                        </IconButton>
                      ))}
                  </>
                ) : (
                  "-"
                )
              ) : (
                "-"
              )}
            </span>
          </span>
        )}
      </StyledTableCell>
    </>
  );
}

function Row({
  childRow,
  index,
  handleRevert,
  site,
  level,
  item,
  color,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  tableData,
  setData,
  totalProfit,
  navigateToLedger,

  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  isBalanceWiseData,
  getBalanceSheetData,
  endPoints,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { childList } = childRow;

  const [rowData, setRowData] = useState(childRow || {});
  const [isAccount, setIsAccount] = useState(false);
  const [accountSerchValue, setAccountSearchValue] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const [balanceStatus, setBalanceStatus] = useState(true);
  const [infiniteLoaderKey, setInfiniteLoaderKey] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [isContainAccounts, setIsContainAccounts] = useState(false);
  const [isClosingBalanceUpdateable, setIsClosingBalanceUpdateable] =
    useState(false);

  const isLeafNode = !childList || childList.length == 0;

  const handleClickIcon = (row) => {
    if (!isCollapsed) {
      if (
        !row.childList ||
        (row.childList && row.childList.length == 0) ||
        row.isContainsAccounts
      ) {
        setGroupName(row?.name);
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setIsCollapsed(!isCollapsed);
      // Set isCollapsed to true before making the API call
    } else setIsCollapsed(!isCollapsed); // Toggle isCollapsed state
  };

  const makeAPICallOnLeafNode = async (row) => {
    setIsClosingBalanceUpdateable(row.isClosingBalanceUpdateable);
    const payload = {
      reportId: 2,
      groupId: row?.id,
      siteId: localStorage.getItem("siteId"),
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
      groupIdsForPLBAdjustment: row?.groupIdsForPLBAdjustment || null,
      isOpeningBalanceConsider: row?.isOpeningBalanceConsider || null,
      isOpeningBalanceForSubGroup: row?.isOpeningBalanceForSubGroup || null,
      plbalanceForAdjustment: row?.plbalanceForAdjustment || null,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(
        apiUrl,
        encryptedPayload,
        null,
        true
      );

      if (response && response.data && response.data.status == 200) {
        setLoading(false);
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList,
            accountList: [],
          };
          const balanceFilterRow = {
            ...row,
            childList: zeroBalanceReportFilter(
              response?.data?.data[0]?.childList
            ),
            accountList: [],
          };
          if (isBalanceWiseData) {
            setRowData(balanceFilterRow);
            if (row?.isContainsAccounts) {
              getAccountsList(
                row,
                1,
                false,
                balanceStatus,
                true,
                balanceFilterRow
              );
            }
            if (balanceFilterRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              // setIsCollapsed(false);
              errorAlert("There are no accounts that have a balance!");
            }
          } else {
            setRowData(updatedRow);
            if (row?.isContainsAccounts) {
              getAccountsList(row, 1, false, balanceStatus, true, updatedRow);
            }
            if (updatedRow?.childList?.length > 0) {
              setIsCollapsed(true);
            } else {
              // setIsCollapsed(false);
            }
          }

          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // setIsCollapsed(true);
        } else getAccountsList(row, 1, false, balanceStatus, true);
      } else {
        setLoading(false);
        setIsCollapsed(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      setIsCollapsed(false);
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getAccountsList = async (
    row,
    pageN,
    isSearch,
    balanceStatus,
    fetch,
    groupUpdatedRow
  ) => {
    const payload = {
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
      siteId: parseInt(localStorage.getItem("siteId")),
      accountGroupIds: [row?.id],
      financialYearId: 5,
      pageNo: pageN || 1,
      pageSize: 20,
      searchKey: accountSerchValue
        ? accountSerchValue?.trim().replace(/\s+/g, " ")
        : "",
      isHavingTransaction: balanceStatus,
      groupIdsForPLBAdjustment: row?.groupIdsForPLBAdjustment || null,
      isOpeningBalanceConsider: row?.isOpeningBalanceConsider || null,
      isOpeningBalanceForSubGroup: row?.isOpeningBalanceForSubGroup || null,
      plbalanceForAdjustment: row?.plbalanceForAdjustment || null,
    };

    const apiUrl = trialBalanceUrl;
    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      pageN === 1 && setLoading(true);
      setIsCollapsed(true);
      setIsContainAccounts(true);
      const response = await userService.postData(apiUrl, encryptedPayload);

      if (
        response &&
        response.data &&
        response.data.status === 200 &&
        response.data.data.data
      ) {
        pageN === 1 && setLoading(false);
        const { accountInfoList } = response.data.data?.data;

        if (accountInfoList && accountInfoList.length > 0) {
          // setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            // childList: [], // Make sure to set childList as an empty array
          }));

          const updatedRow =
            rowData?.accountList && !isSearch && !fetch
              ? {
                  ...row,
                  childList: rowData?.childList,
                  accountList: [...rowData?.accountList, ...newList],
                }
              : {
                  ...row,
                  childList: groupUpdatedRow?.childList,
                  accountList: newList,
                };
          // const updatedRow = { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // Toggle isCollapsed state
        } else {
          if (pageN === 1) {
            const updatedRow = {
              ...row,
              childList: groupUpdatedRow?.childList,
              accountList: [],
            };
            setRowData(updatedRow);
            setLoading(false);
            successAlert(`No Account available for ${row.name}`);
          }
          // If there are no child items, just toggle isCollapsed state
          // setIsCollapsed(!isCollapsed);
          if (rowData.childList || rowData.accountList) {
            if (
              rowData.childList?.length === 0 ||
              rowData.accountList?.length === 0
            ) {
              successAlert(`No Account available for ${row.name}`);
            }
          } else {
            successAlert(`No Account available for ${row.name}`);
          }
        }
      } else {
        pageN === 1 && setLoading(false);
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      pageN === 1 && setLoading(false);
      if (rowData.childList || rowData.accountList) {
        if (
          rowData.childList?.length === 0 ||
          rowData.accountList?.length === 0
        ) {
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        successAlert(`No Account available for ${row.name}`);
      }
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      // setLoading(false);
    }
  };

  // const color = getColorForLevel(level);
  const paddingLeft = `10px!important`;

  const resetInfiniteLoader = () => {
    setInfiniteLoaderKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    setPageNum(1);
  };

  const isAccountLoaded = ({ index }) => {
    if (!isAccount) return;
    const loadedItem = !!rowData.accountList[index];
    return loadedItem;
  };

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    let quotient = Math.ceil(stopIndex / 20);
    const pageSize = quotient + 1;
    console.log(pageSize);
    if (pageNum === pageSize) {
      return;
    }
    setPageNum(pageSize);
    getAccountsList(childRow, pageSize, false, balanceStatus, undefined);
  };

  const searchAccountHandle = () => {
    setTimeout(() => {
      resetInfiniteLoader();
    }, 500);
    getAccountsList(childRow, 1, true, balanceStatus, undefined, rowData);
  };

  useEffect(() => {
    if (childRow) {
      setRowData(childRow);
    }
  }, [childRow]);
  useEffect(() => {
    if (level === 0) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [tableData]);

  return (
    <React.Fragment>
      <StyledTableRow
        className="plRow"
        sx={{
          cursor: "pointer",
          backgroundColor: `${color}!important`,
          marginTop: "1rem!important",
        }}
      >
        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton
                aria-label="expand rowData"
                size="small"
                onClick={() => handleClickIcon(rowData)}
              >
                {isCollapsed ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <span style={{ color: rowData?.isAccount && "blue" }}>
                <Editedtext num="50" text={rowData?.name} />
              </span>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton disabled>
                <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
              </IconButton>
              <span
                style={{ color: rowData?.isAccount && "blue" }}
                onClick={() => navigateToLedger(rowData)}
              >
                <Editedtext num="50" text={rowData?.name} />
              </span>
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell sx={{ padding: "5px 0px!important" }}>
          {!getApprovedBal ? (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color:
                    rowData?.accountGroupCurrentBalance > 0
                      ? "red"
                      : rowData?.approvedAccountGroupCurrentBalance > 0
                      ? "red"
                      : "green",
                }}
              >
                {rowData?.openingBalance !== null &&
                rowData?.openingBalance !== undefined &&
                rowData?.accountGroupCurrentBalance !== null &&
                rowData?.accountGroupCurrentBalance !== undefined ? (
                  <>
                    {showOpeningBalance && (
                      <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                        <AmountInfo amount={rowData?.openingBalance} />
                        {" | "}{" "}
                      </span>
                    )}
                    <AmountInfo amount={rowData?.accountGroupCurrentBalance} />
                  </>
                ) : (
                  "-"
                )}
              </span>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {" "}
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "10px",
                  color: rowData?.approvedAccountGroupCurrentBalance
                    ? rowData?.approvedAccountGroupCurrentBalance > 0
                      ? "red"
                      : "green"
                    : rowData?.approvedClosingBalance
                    ? rowData?.approvedClosingBalance -
                        rowData?.approvedOpeningBalance ==
                      0
                      ? "black"
                      : rowData?.approvedClosingBalance > 0
                      ? "red"
                      : "green"
                    : "red",
                }}
              >
                {rowData?.approvedAccountGroupCurrentBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedAccountGroupCurrentBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo
                        amount={rowData?.approvedAccountGroupCurrentBalance}
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : rowData?.approvedClosingBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedClosingBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo amount={rowData?.approvedClosingBalance} />
                    </>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </span>
            </span>
          )}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          colSpan={3}
          sx={{ padding: "5px 0px!important", borderBottom: "none" }}
        >
          <Collapse in={isCollapsed}>
            <Box>
              <TableContainer>
                <Table>
                  <TableBody>
                    {rowData &&
                      rowData.childList &&
                      rowData.childList.length > 0 &&
                      rowData.childList.map((childRow, childIndex) => (
                        <Row
                          key={childRow.name}
                          childRow={childRow}
                          index={childIndex + 1}
                          handleRevert={handleRevert}
                          site={site}
                          level={level + 1}
                          item={item}
                          color={"#F5FAFF"}
                          setLoading={setLoading}
                          successAlert={successAlert}
                          errorAlert={errorAlert}
                          getApprovedBal={getApprovedBal}
                          tableData={tableData}
                          setData={setData}
                          totalProfit={totalProfit}
                          navigateToLedger={navigateToLedger}
                          fromDate={fromDate}
                          toDate={toDate}
                          monthId={monthId}
                          showOpeningBalance={showOpeningBalance}
                          isBalanceWiseData={isBalanceWiseData}
                          getBalanceSheetData={getBalanceSheetData}
                          endPoints={endPoints}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {isContainAccounts && (
                <Grid container alignItems={"center"} mt={1}>
                  <Grid item sx={{ marginRight: "20px" }}>
                    <TextField
                      value={accountSerchValue}
                      onChange={(e) => setAccountSearchValue(e.target.value)}
                      margin="dense"
                      size="small"
                      style={{
                        maxWidth: 250,
                      }}
                      label="Search"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={searchAccountHandle}
                            >
                              <TbWorldSearch />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MySwitch
                      checked={balanceStatus}
                      onChange={(e) => {
                        setBalanceStatus(e.target.checked);
                        setTimeout(() => {
                          resetInfiniteLoader();
                        }, 500);
                        getAccountsList(
                          childRow,
                          1,
                          Boolean(accountSerchValue?.trim()),
                          e.target.checked,
                          true,
                          rowData
                        );
                      }}
                      label={!balanceStatus ? "Balance On" : "Balance Off"}
                    />
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: themecolor, fontWeight: 700 }}>
                      {groupName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {(rowData?.accountList?.length > 0 || isAccount) && (
                <TableContainer>
                  <Table>
                    <TableBody>
                      <AutoSizer
                        style={{
                          height: heightCalculate(
                            rowData?.accountList,
                            50,
                            300
                          ),
                        }}
                      >
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            <InfiniteLoader
                              key={infiniteLoaderKey}
                              isRowLoaded={isAccountLoaded}
                              loadMoreRows={loadMoreRows}
                              rowCount={
                                Math.ceil(
                                  rowData.accountList?.length / itemsPerRow
                                ) + 1
                              }
                            >
                              {({ onRowsRendered, registerChild }) => (
                                <List
                                  onRowsRendered={onRowsRendered}
                                  ref={registerChild}
                                  width={width}
                                  height={heightCalculate(
                                    rowData?.accountList,
                                    50,
                                    300
                                  )}
                                  rowHeight={50}
                                  rowCount={Math.ceil(
                                    rowData.accountList?.length / itemsPerRow
                                  )}
                                  overscanRowCount={3}
                                  rowRenderer={({ index, key, style }) => {
                                    const startIndex = index * itemsPerRow;
                                    const endIndex = Math.min(
                                      startIndex + itemsPerRow,
                                      rowData.accountList?.length
                                    );
                                    const items = rowData.accountList
                                      ?.slice(startIndex, endIndex)
                                      .map((val, ind) => (
                                        <AccountsRow
                                          paddingLeft={paddingLeft}
                                          val={val}
                                          getApprovedBal={getApprovedBal}
                                          showOpeningBalance={
                                            showOpeningBalance
                                          }
                                          isClosingBalanceUpdateable={
                                            isClosingBalanceUpdateable
                                          }
                                          errorAlert={errorAlert}
                                          rowData={rowData}
                                          navigateToLedger={navigateToLedger}
                                          getAccountsList={getAccountsList}
                                          childRow={childRow}
                                          balanceStatus={balanceStatus}
                                          getBalanceSheetData={
                                            getBalanceSheetData
                                          }
                                          successAlert={successAlert}
                                          endPoints={endPoints}
                                        />
                                      ));

                                    return (
                                      <StyledTableRow
                                        key={key}
                                        style={style}
                                        sx={{
                                          backgroundColor: `${color}!important`,
                                        }}
                                      >
                                        {items}
                                      </StyledTableRow>
                                    );
                                  }}
                                />
                              )}
                            </InfiniteLoader>
                          );
                        }}
                      </AutoSizer>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function BalanceSheetTable({
  data,
  setData,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,
  totalRevenue,
  totalExpenses,
  netAmount,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  navigateToLedger,
  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
  isBalanceWiseData,
  vertHoriOpen,
  getBalanceSheetData,
  endPoints,
}) {
  const onPageChange = (event, value) => setPageNo(value);

  // Filter out the "Income" and "Expenses" sections
  const filteredData = Object.keys(data)
    .filter(
      (key) =>
        key === "leftAccountGroupFetchResponseList" ||
        key === "rightAccountGroupFetchResponseList"
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  const reversedFilteredData = Object.keys(filteredData)
    .reverse()
    .reduce((obj, key) => {
      obj[key] = filteredData[key];
      return obj;
    }, {});

  const getColorBasedOnSubtraction = (
    dataArray,
    balanceKey,
    openingBalanceKey
  ) => {
    return (
      dataArray &&
      dataArray.map((accountGroup) => {
        const balanceDifference =
          accountGroup[balanceKey] - accountGroup[openingBalanceKey];

        if (balanceDifference === 0) {
          return "black";
        } else if (balanceDifference > 0) {
          return "red";
        } else {
          return "green";
        }
      })
    );
  };

  function getTotalProfitLoss(data, isApprove) {
    if (!data) return 0;
    if (isApprove) {
      return data?.plbAccountGroupFetchResponseList?.reduce(
        (total, child) => total + child.approvedAccountGroupCurrentBalance,
        0
      );
    } else {
      return data?.plbAccountGroupFetchResponseList?.reduce(
        (total, child) => total + child.accountGroupCurrentBalance,
        0
      );
    }
  }

  function getTotalProfitLossGroup(data) {
    if (!data) return 0;
    return data?.plbAccountGroupFetchResponseList?.reduce(
      (total, child) => total + Math.abs(child.accountGroupCurrentBalance),
      0
    );
  }

  function isProfitBalanceSheet(data, isApproved) {
    if (!data) return null;
    for (let i = 0; i < data.length; i++) {
      const ele = data[i];
      if (ele.isPLBCalcRequired === null) return null;
      if (ele.isPLBCalcRequired) {
        if (!isApproved) {
          return ele.accountGroupCurrentBalance > 0;
        } else {
          return ele.approvedAccountGroupCurrentBalance > 0;
        }
      }
    }
  }

  const checkSide = isProfitBalanceSheet(
    data?.plbAccountGroupFetchResponseList,
    getApprovedBal
  );

  console.log(reversedFilteredData);
  console.log(reversedFilteredData.leftAccountGroupFetchResponseList);
  return (
    <>
      <TableContainer
        style={{ overflow: "auto", height: vertHoriOpen ? "60vh" : "auto" }}
      >
        <Grid container spacing={2}>
          {reversedFilteredData &&
          Object.keys(reversedFilteredData).length > 0 ? (
            Object.keys(reversedFilteredData).map((item) => {
              return (
                <>
                  <Grid
                    item
                    md={vertHoriOpen ? 12 : 6}
                    lg={vertHoriOpen ? 12 : 6}
                    key={item} // Ensure to add a unique key here
                    style={{
                      maxHeight: "calc(-290px + 100vh)",
                      overflow: "auto",
                    }}
                  >
                    <Table aria-label="collapsible table" stickyHeader>
                      <TableHead>
                        <StyledTableRow
                          sx={{ position: "sticky", zIndex: 10000 }}
                        >
                          <StyledTableCell
                            colSpan={12}
                            sx={{ borderRadius: "6px", textAlign: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0 20px",
                              }}
                            >
                              <p style={{ textTransform: "capitalize" }}>
                                {reversedFilteredData[item]?.length > 0 &&
                                  reversedFilteredData[item][0]?.name}
                              </p>
                              <p>
                                Amount (
                                {!getApprovedBal ? (
                                  <AmountInfo
                                    // amount={reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                                    //   (total, child) =>
                                    //     total +
                                    //     child?.accountGroupCurrentBalance,
                                    //   0
                                    // )}

                                    amount={
                                      reversedFilteredData[item][0]
                                        ?.accountGroupCurrentBalance
                                    }
                                  />
                                ) : (
                                  <AmountInfo
                                    amount={
                                      reversedFilteredData[item][0]
                                        ?.approvedAccountGroupCurrentBalance ||
                                      0
                                    }
                                  />
                                )}
                                )
                              </p>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <div style={{ height: "9px" }} />
                      {reversedFilteredData[item].length > 0 ? (
                        <TableBody sx={{ overflow: "scroll" }}>
                          {reversedFilteredData[item].map((row, index) => (
                            <Row
                              key={row.name} // Ensure row name is unique
                              childRow={row}
                              index={(pageNo - 1) * pageSize + index + 1}
                              handleRevert={handleRevert}
                              site={site}
                              level={0}
                              item={item}
                              color={"#DDEEFF"}
                              setLoading={setLoading}
                              successAlert={successAlert}
                              errorAlert={errorAlert}
                              getApprovedBal={getApprovedBal}
                              tableData={data}
                              setData={setData}
                              totalProfit={
                                getApprovedBal
                                  ? data?.netApprovedBalance
                                  : data?.netBalance
                              }
                              navigateToLedger={navigateToLedger}
                              fromDate={fromDate}
                              toDate={toDate}
                              monthId={monthId}
                              showOpeningBalance={showOpeningBalance}
                              isBalanceWiseData={isBalanceWiseData}
                              getBalanceSheetData={getBalanceSheetData}
                              endPoints={endPoints}
                            />
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell
                              className="tableData"
                              colSpan={vertHoriOpen ? 12 : 6}
                              sx={{ textAlign: "center" }}
                            >
                              No Data to Show
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      )}
                    </Table>
                  </Grid>
                  {/* Total Revenue grid print after Revenue section */}
                  {vertHoriOpen &&
                    checkSide !== null &&
                    checkSide &&
                    item == "leftAccountGroupFetchResponseList" && (
                      <Grid container spacing={1} item md={12}>
                        <Grid item md={12} lg={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderRadius: "6px",
                              background: themecolor,
                              height: "38px",
                              alignItems: "center",
                              padding: "0px 15px",
                              fontWeight: "600",
                              marginTop: "1rem",
                              color: "#fff",
                            }}
                          >
                            <p>{"Profit & Loss A/c"}</p>

                            <p>
                              <AmountInfo
                                amount={getTotalProfitLoss(
                                  data,
                                  getApprovedBal
                                )}
                              />
                            </p>
                          </div>
                        </Grid>

                        {data &&
                          data?.plbAccountGroupFetchResponseList?.map((ele) => (
                            <Grid item md={12} lg={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderRadius: "6px",
                                  background: "#fff",
                                  height: "25px",
                                  alignItems: "center",
                                  padding: "0px 10px",
                                  fontWeight: "600",
                                }}
                              >
                                <p>{ele?.name || "NA"}</p>

                                <p>
                                  <span
                                    style={{
                                      color: getApprovedBal
                                        ? getAmountColor(
                                            ele.approvedAccountGroupCurrentBalance
                                          )
                                        : getAmountColor(
                                            ele.accountGroupCurrentBalance
                                          ),
                                    }}
                                  >
                                    {getApprovedBal
                                      ? ele.approvedAccountGroupCurrentBalance <
                                          0 && "-"
                                      : ele.accountGroupCurrentBalance < 0 &&
                                        "-"}
                                  </span>
                                  <AmountInfo
                                    color={
                                      getApprovedBal
                                        ? getAmountColor(
                                            ele.approvedAccountGroupCurrentBalance
                                          )
                                        : getAmountColor(
                                            ele.accountGroupCurrentBalance
                                          )
                                    }
                                    amount={
                                      getApprovedBal
                                        ? ele.approvedAccountGroupCurrentBalance
                                        : ele.accountGroupCurrentBalance
                                    }
                                  />
                                </p>
                              </div>
                            </Grid>
                          ))}
                      </Grid>
                    )}
                  {vertHoriOpen &&
                    reversedFilteredData[item][0]?.name === "Liabilities" &&
                    false && (
                      <Grid item md={12} lg={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "6px",
                            backgroundColor: vertHoriOpen
                              ? themecolor
                              : "#a7f3a7",
                            height: "38px",
                            alignItems: "center",
                            padding: "0px 15px",
                            fontWeight: "600",
                            marginTop: "1rem",
                            color: vertHoriOpen && "#fff",
                          }}
                        >
                          <p>{"Total"}</p>
                          <p
                            style={{
                              color: getColorBasedOnSubtraction(
                                reversedFilteredData?.leftAccountGroupFetchResponseList,
                                getApprovedBal
                                  ? "approvedAccountGroupCurrentBalance"
                                  : "accountGroupCurrentBalance",
                                getApprovedBal
                                  ? "approvedOpeningBalance"
                                  : "openingBalance"
                              ),
                            }}
                          >
                            {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                              <AmountInfo
                                amount={reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                                  (total, child) =>
                                    total +
                                    (getApprovedBal
                                      ? child?.approvedAccountGroupCurrentBalance
                                      : child?.accountGroupCurrentBalance),
                                  0
                                )}
                              />
                            )}
                          </p>
                        </div>
                      </Grid>
                    )}
                </>
              );
            })
          ) : (
            <>
              {["Revenue", "Expenses"].map((label) => (
                <Grid
                  item
                  md={vertHoriOpen ? 12 : 6}
                  lg={vertHoriOpen ? 12 : 6}
                  key={label}
                >
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell colSpan={12}>{label}</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={12}
                          sx={{ textAlign: "center" }}
                        >
                          No Data to Show
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </TableContainer>

      <Grid container spacing={2}>
        {/*================= Left group ================= */}
        <Grid item md={vertHoriOpen ? 12 : 6}>
          {!vertHoriOpen && checkSide !== null && checkSide && (
            <Grid container spacing={1}>
              <Grid item md={12} lg={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "6px",
                    background: themecolor,
                    height: "38px",
                    alignItems: "center",
                    padding: "0px 15px",
                    fontWeight: "600",
                    marginTop: "1rem",
                    color: "#fff",
                  }}
                >
                  <p>{"Profit & Loss A/c"}</p>

                  <p>
                    <AmountInfo
                      amount={getTotalProfitLoss(data, getApprovedBal)}
                    />
                  </p>
                </div>
              </Grid>

              {data &&
                data?.plbAccountGroupFetchResponseList?.map((ele) => (
                  <Grid item md={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "6px",
                        background: "#fff",
                        height: "25px",
                        alignItems: "center",
                        padding: "0px 10px",
                        fontWeight: "600",
                      }}
                    >
                      <p>{ele?.name || "NA"}</p>

                      <p>
                        {/* <span
                          style={{
                            color: getApprovedBal
                              ? getAmountColor(
                                  ele.approvedAccountGroupCurrentBalance
                                )
                              : getAmountColor(ele.accountGroupCurrentBalance),
                          }}
                        >
                          {getApprovedBal
                            ? ele.approvedAccountGroupCurrentBalance < 0 && "-"
                            : ele.accountGroupCurrentBalance < 0 && "-"}
                        </span> */}
                        <AmountInfo
                          color={
                            getApprovedBal
                              ? getAmountColor(
                                  ele.approvedAccountGroupCurrentBalance
                                )
                              : getAmountColor(ele.accountGroupCurrentBalance)
                          }
                          amount={
                            getApprovedBal
                              ? ele.approvedAccountGroupCurrentBalance
                              : ele.accountGroupCurrentBalance
                          }
                        />
                      </p>
                    </div>
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>

        {/*================= Right group ================= */}
        <Grid item md={vertHoriOpen ? 12 : 6}>
          {checkSide !== null && !checkSide && (
            <Grid container spacing={1}>
              <Grid item md={12} lg={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "6px",
                    background: themecolor,
                    height: "38px",
                    alignItems: "center",
                    padding: "0px 10px",
                    fontWeight: "600",
                    // marginTop: "1rem",
                    color: "#fff",
                  }}
                >
                  <p>{"Profit & Loss A/c"}</p>

                  <p>
                    <AmountInfo
                      amount={
                        data &&
                        data?.plbAccountGroupFetchResponseList?.reduce(
                          (total, child) =>
                            total + Math.abs(child.accountGroupCurrentBalance),
                          0
                        )
                      }
                    />
                  </p>
                </div>
              </Grid>

              {data &&
                data?.plbAccountGroupFetchResponseList?.map((ele) => (
                  <Grid item md={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "6px",
                        background: "#fff",
                        height: "25px",
                        alignItems: "center",
                        padding: "0px 10px",
                        fontWeight: "600",
                      }}
                    >
                      <p>{ele?.name || "NA"}</p>

                      <p>
                        {/* <span
                          style={{
                            color: getApprovedBal
                              ? getAmountColor(
                                  ele.approvedAccountGroupCurrentBalance
                                )
                              : getAmountColor(ele.accountGroupCurrentBalance),
                          }}
                        >
                          {getApprovedBal
                            ? ele.approvedAccountGroupCurrentBalance < 0 && "-"
                            : ele.accountGroupCurrentBalance < 0 && "-"}
                        </span> */}
                        <AmountInfo
                          color={
                            getApprovedBal
                              ? getAmountColor(
                                  ele.approvedAccountGroupCurrentBalance
                                )
                              : getAmountColor(ele.accountGroupCurrentBalance)
                          }
                          amount={ele.accountGroupCurrentBalance}
                        />
                      </p>
                    </div>
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {/*================= Left group ================= */}
        {!vertHoriOpen && (
          <Grid item md={vertHoriOpen ? 12 : 6} lg={vertHoriOpen ? 12 : 6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "6px",
                textAlign: "center",
                fontWeight: 600,
                backgroundColor: "#a7f3a7",
                height: "38px",
                alignItems: "center",
                padding: "0px 15px",
                fontWeight: "600",
                marginTop: "1rem",
              }}
            >
              <p>{"Total"}</p>
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.leftAccountGroupFetchResponseList,
                    getApprovedBal
                      ? "approvedAccountGroupCurrentBalance"
                      : "accountGroupCurrentBalance"
                  ),
                }}
              >
                {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      checkSide
                        ? getTotalProfitLoss(data, getApprovedBal) +
                          Math.abs(
                            getTotalGoupBalance(
                              reversedFilteredData.leftAccountGroupFetchResponseList,
                              getApprovedBal
                            )
                          )
                        : getTotalGoupBalance(
                            reversedFilteredData.leftAccountGroupFetchResponseList,
                            getApprovedBal
                          )
                      // + (netAmount < 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            </div>
          </Grid>
        )}

        {/*================= Right group ================= */}
        {/* {!vertHoriOpen && ( */}
        <Grid item md={vertHoriOpen ? 12 : 6} lg={vertHoriOpen ? 12 : 6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              background: "#f3a0a0",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem",
            }}
          >
            <p>{"Total"}</p>
            <p
              style={{
                color: getColorBasedOnSubtraction(
                  reversedFilteredData?.rightAccountGroupFetchResponseList,
                  getApprovedBal
                    ? "approvedAccountGroupCurrentBalance"
                    : "accountGroupCurrentBalance",
                  getApprovedBal ? "approvedOpeningBalance" : "openingBalance"
                ),
              }}
            >
              {reversedFilteredData?.rightAccountGroupFetchResponseList && (
                <AmountInfo
                  amount={
                    !checkSide
                      ? getTotalProfitLoss(data, getApprovedBal) +
                        Math.abs(
                          getTotalGoupBalance(
                            reversedFilteredData.rightAccountGroupFetchResponseList,
                            getApprovedBal
                          )
                        )
                      : getTotalGoupBalance(
                          reversedFilteredData.rightAccountGroupFetchResponseList,
                          getApprovedBal
                        )
                    // + (netAmount < 0 ? netAmount : 0)
                  }
                />
              )}
            </p>
          </div>
        </Grid>
        {/* )} */}
      </Grid>

      <ColorInfo />
      <Divider />
    </>
  );
}
