import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Chip,
  Grid,
  InputAdornment,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import moment from "moment";
import AmountInfo from "../CommonComps/AmountInfo";
import { voucherTypesUrlV1 } from "../config";
import { userService } from "../userService";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import pdfLogo from "../images/accounting.png";
import { getVouchersData } from "./getVouchersData";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ContraVoucherPreview = ({ open, handleClose, title }) => {
  const pdfRef = React.useRef(null);
  const qrValue = 12345;

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{ "& .MuiDialog-paper": { overflow: "visible" } }}
        open={open}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box ref={pdfRef} id="divToPrint">
            <Grid container mb={1}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component={"h2"}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Your Site Name
                    {/* {companyDto?.companyName} - {site?.name} */}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component={"h2"}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Contra Voucher
                  </Typography>
                  <Typography
                    sx={{ fontWeight: "300", color: "gray", fontSize: "13px" }}
                  >
                    {"-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} textAlign={"end"}>
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      height: "auto",
                      maxWidth: 124,
                      width: "100%",
                      marginLeft: "auto",
                    }}
                  >
                    <QRCode
                      size={356}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrValue && JSON.stringify(qrValue)}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container mb={1}>
              <Grid item md={6}>
                {/* <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "13px",
                    fontWeight: "300",
                  }}
                >
                  Ref.:{" "}
                  <span style={{ fontWeight: "500" }}>
                    {data[0]?.invoiceNo || "-"}
                  </span>
                </Typography> */}
              </Grid>
              <Grid item md={6} textAlign={"end"}>
                <Box>
                  <Typography
                    sx={{ fontWeight: "300", color: "gray", fontSize: "13px" }}
                  >
                    <em>21-Jun-2024</em>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {" "}
                          <b> Particulars </b>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                            textAlign: "right",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {" "}
                          <b> Debit </b>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                          }}
                        >
                          {" "}
                          <b> Credit </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                            padding: 0,
                            paddingRight: 1,
                            // paddingLeft: 3,
                            verticalAlign: "top",
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            // key={i}
                          >
                            <Typography
                              component={"p"}
                              sx={{ fontSize: "13px", width: "80%" }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "13px",
                                  color: "rgb(38, 123, 229)",
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>To - </span>
                                Abc Limited <br></br>
                              </Typography>
                              <em style={{ marginLeft: "20px" }}>
                                ( Narration )
                              </em>
                            </Typography>
                            <Typography
                              component={"p"}
                              sx={{
                                fontSize: "13px",
                                width: "20%",
                                textAlign: "end",
                              }}
                            >
                              {/* Dr */}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                            padding: 0,
                            paddingTop: 2,
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                        ></TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                            padding: 0,
                            verticalAlign: "top",
                            paddingRight: 1,
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                        >
                          <Typography
                            component={"p"}
                            // key={i}
                            sx={{ fontSize: "13px" }}
                          >
                            700
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                            padding: 0,
                            verticalAlign: "top",
                            paddingRight: 1,
                            paddingTop: 2,
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                          className="particular_acc"
                        >
                          <Box
                            className="targetName"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            // key={i}
                          >
                            <Typography
                              component={"p"}
                              sx={{
                                fontSize: "13px",
                                width: "80%",
                                fontWeight: "600",
                                color: "rgb(38, 123, 229)",
                              }}
                            >
                              Xyz Private Limited
                            </Typography>
                            <Typography
                              component={"p"}
                              sx={{
                                fontSize: "13px",
                                width: "20%",
                                textAlign: "end",
                              }}
                            >
                              Dr
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            verticalAlign: "top",
                            borderBottom: 0,
                            paddingRight: 1,
                            padding: 0,
                            paddingTop: 2,
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                        >
                          <Typography component={"p"} sx={{ fontSize: "13px" }}>
                            700
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                            padding: 0,
                            paddingTop: 2,
                            height: "45px",
                            maxHeight: "fit-content",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 0,
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            borderBottom: 0,
                          }}
                        >
                          <b>On Account of :</b>
                          <Typography
                            component={"h3"}
                            sx={{ fontSize: "13px", marginLeft: "20px" }}
                          >
                            {"-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            verticalAlign: "bottom",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: 0,
                            borderBottom: 0,
                          }}
                        >
                          <Typography
                            component={"h3"}
                            sx={{
                              borderTop: "1px solid rgba(224, 224, 224, 1)",
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            700
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            verticalAlign: "bottom",
                            padding: 0,
                            borderBottom: 0,
                          }}
                        >
                          <Typography
                            component={"h3"}
                            sx={{
                              borderTop: "1px solid rgba(224, 224, 224, 1)",
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            700
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item md={2}></Grid>
              <Grid item md={4}></Grid>
              <Grid item md={6} textAlign={"end"}>
                <Typography
                  component={"h2"}
                  sx={{
                    fontSize: "13px",
                    fontWeight: "300",
                    marginTop: "55px",
                  }}
                >
                  Authorised Signatory
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained">
            Print
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default ContraVoucherPreview;
