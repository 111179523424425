import * as React from "react";
import { headers, pageSizes } from "../Account/util";
import { useNavigate } from "react-router-dom";
import { DataStatus } from "../CommonComps/DataStatus";
import {
  StyledTableCell,
  StyledTableRow,
  checkPermissionExist
} from "../utils";
import { Editedtext, EditedtextCode } from "../CommonComps/TextTemplate";
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  Checkbox
} from "@mui/material";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import { MdRealEstateAgent } from "react-icons/md";
import CommonPagination from "../CommonComps/CommonPagination";
import { useSelector } from "react-redux";
import {
  accountConfigUpdate,
  singleAccountUrl,
  singleTransactionGetUrl
} from "../config";
import AmountInfo from "../CommonComps/AmountInfo";
import ColorInfo from "../CommonComps/ColorInfo";

const rightAlign = { textAlign: "right" };

function Row({ row, index, handleCheckboxChange, parentInd, data, path }) {
  const endPoints = useSelector(
    (val) => val?.rootReducer?.permissionReducer?.data
  );

  const {
    name,
    code,
    openingBalance,
    openingBalanceTypeName,
    balance,
    balanceTypeName,
    createdOn,
    accountTypeName,
    permissionStateId,
    isSelected,
    isEnableForCurrentSite
  } = row;
  const navigate = useNavigate();

  const handleEditCategory = (row) =>
    navigate("/addAccount", { state: row.id });
  const overDueAmount =
    (row?.overdue15Amount || 0) +
    (row?.overdue30Amount || 0) +
    (row?.overdue45Amount || 0) +
    (row?.overdue60Amount || 0) +
    (row?.overdue90Amount || 0) +
    (row?.overdueAbove90Amount || 0);

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell style={{ paddingLeft: 10 }}>
          <div
            style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}
          >
            <Checkbox
              disabled={isEnableForCurrentSite}
              checked={isEnableForCurrentSite ? true : isSelected}
              onChange={(e) => {
                // e.stopPropagation();
                handleCheckboxChange(e.target.checked, parentInd); // Call the shared handler when this checkbox changes
              }}
              color="primary"
              style={{
                display: checkPermissionExist(endPoints, accountConfigUpdate)
                  ? "inline-flex"
                  : "none"
              }}
            />

            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {index}
            </Typography>
          </div>
        </StyledTableCell>

        <StyledTableCell
          sx={{
            cursor:
              checkPermissionExist(
                endPoints,
                `${singleAccountUrl}/{account-id}`
              ) && "pointer"
          }}
          onClick={() => {
            if (
              path !== "pendingApprovals" &&
              checkPermissionExist(
                endPoints,
                `${singleAccountUrl}/{account-id}`
              )
            ) {
              handleEditCategory(row);
            }
          }}
        >
          {name}
          {/* <EditedtextCode
            text={name}
            code={code}
            textColor="blue"
            codeColor="black"
          /> */}
        </StyledTableCell>
        <StyledTableCell>{row?.generateAccountNumber || "-"}</StyledTableCell>
        {/* <StyledTableCell>{code}</StyledTableCell> */}
        <StyledTableCell>
          <Editedtext text={accountTypeName} num="30" />{" "}
        </StyledTableCell>
        <StyledTableCell align="center">
          <span style={{ color: isEnableForCurrentSite ? "green" : "red" }}>
            {isEnableForCurrentSite ? "True" : "False"}
          </span>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: "right" }}>
          <span
            style={{ color: openingBalanceTypeName === "Cr" ? "red" : "green" }}
          >
            {+parseInt(Math.abs(openingBalance)) ? (
              <AmountInfo
                amount={openingBalance}
                color={openingBalanceTypeName === "Cr" ? "red" : "green"}
              />
            ) : (
              "0"
            )}
            &nbsp; {openingBalanceTypeName}
          </span>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: "right" }}>
          <span style={{ color: balanceTypeName === "Cr" ? "red" : "green" }}>
            {+parseInt(Math.abs(balance)) && (
              <AmountInfo
                amount={balance}
                color={balanceTypeName === "Cr" ? "red" : "green"}
              />
            )}
            &nbsp; {balanceTypeName}
          </span>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: "right" }}>
          <AmountInfo amount={row?.currentDueAmount || 0} />
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: "right" }}>
          <AmountInfo amount={overDueAmount || 0} color={"red"} />
        </StyledTableCell>

        <StyledTableCell style={rightAlign}>
          {moment(createdOn).format("DD-MM-YYYY, hh:mm a")}
        </StyledTableCell>

        {/* <StyledTableCell style={rightAlign}>
          <IconButton>
            {kycStatusName === "COMPLETED" ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : kycStatusName === "PENDING" ? (
              <PendingIcon style={{ color: "#ff8d00" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}
          </IconButton>
          
        </StyledTableCell> */}

        <StyledTableCell style={{ textAlign: "right" }}>
          <IconButton
            style={{
              visibility: checkPermissionExist(
                endPoints,
                singleTransactionGetUrl
              )
                ? "visible"
                : "hidden"
            }}
            name={name}
            onClick={() => {
              checkPermissionExist(endPoints, singleTransactionGetUrl) &&
                navigate("/generalLedger", { state: row });
            }}
          >
            <HistoryIcon
              style={{
                color: "green",
                visibility: checkPermissionExist(
                  endPoints,
                  singleTransactionGetUrl
                )
                  ? "visible"
                  : "hidden"
              }}
            />
          </IconButton>

          {/* <IconButton onClick={()=>navigate("/bankReconciliation",{state:row})}>
            <MdRealEstateAgent style={{color:"red"}}/>
          </IconButton> */}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function AccountTable({
  data,
  pageNo,
  setPageNo,
  totalPages,
  pageSize,
  setPageSize,
  handleCheckboxChange,
  path
}) {
  const onPageChange = (event, value) => setPageNo(value);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginTop: 20,
          height: data && data.length > 0 && "calc(100vh - 303px)"
        }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map((val) => (
                  <StyledTableCell style={val.style} key={val.id}>
                    {val?.name == "Status"
                      ? "Enable For current Site"
                      : val?.name}
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  parentInd={index}
                  index={(pageNo - 1) * pageSize + index + 1}
                  handleCheckboxChange={handleCheckboxChange}
                  data={data}
                  path={path}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data && data.length < 1 && <DataStatus />}

      {data && Array.isArray(data) && data.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ColorInfo />
          <CommonPagination
            pageSize={pageSize}
            pageSizes={pageSizes}
            totalPages={totalPages}
            pageNo={pageNo}
            setPageNo={setPageNo}
            setPageSize={setPageSize}
            onPageChange={onPageChange}
            style={{ minWidth: 150, margin: "0  20px" }}
            label="Account Per Page"
          />
        </div>
      )}
    </>
  );
}
