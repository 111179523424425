import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  TableRow,
  TableCell,
  Button,
  Divider,
  Tooltip,
  Checkbox,
  Collapse,
  Box,
  Stack,
} from "@mui/material";
import { FcInfo } from "react-icons/fc";
import { TiInfoLarge } from "react-icons/ti";
import { headers } from "./util";
import { withStyles } from "@material-ui/core";
import { Editedtext } from "../CommonComps/TextTemplate";
import { DataStatus } from "../CommonComps/DataStatus";
import { pageSizes } from "../Account/util";
// import { StyledTableCell, StyledTableRow } from "../utils/index";
import { getEncryptPayload } from "../Network_Utility";
import { EditedtextCode } from "../CommonComps/TextTemplate";
import CommonPagination from "../CommonComps/CommonPagination";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import { BsCheckLg } from "react-icons/bs";
import { themecolor } from "../Navigation";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FaHistory } from "react-icons/fa";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  checkModuleExist,
  checkPathExist,
  checkPermissionExist,
} from "../utils";
import {
  addTransactionUrl,
  generateInvoiceByTransactionId,
  singleTransactionGetUrl,
} from "../config";
import { useSelector } from "react-redux";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import ConvertAmountToWords from "../CommonComps/AmountConverter";
import { Info } from "@mui/icons-material";
import AmountInfo from "../CommonComps/AmountInfo";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { userService } from "../userService";
import AlertDialog from "../CommonComps/AlertDialog";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShowEInvoice from "./ShowE-Invoice";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: ` ${themecolor} !important`,
    padding: "10px 5px !important",
    color: "white !important",
    fontSize: "15px !important",
    // width: "fit-content !important"
  },
  body: {
    fontSize: 13,
    fontWeight: 400,
    padding: "10px 5px!important",
    borderBottom: "none",
    border: "none !important",
    color: "#555",
    // width: "fit-content !important"
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

function Row(props) {
  const {
    row,
    index,
    handleRevert,
    site,
    getSameValue,
    parentEle,
    spanRw,
    count,
    voucherList,
    nextActionList,
    selectedStatus,
    filterStateList,
    handleCheckboxChange,
    path,
    parentInd,
    isSelected,
    SelectedTransactionIds,
    headers,
    showAllTransaction,
    filteredColumns,
    // cancelTransactionHandle,
    setOpenAlertDialog,
    setRevertPayload,
    handleGenerate,
    handleClickOpenDialog,
  } = props;

  const endPoints = useSelector(
    (val) => val?.rootReducer?.permissionReducer?.data
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [images, setImages] = useState([]);
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);

  const openModal = (action) => {
    setAction(action);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleImageUpload = (event) => {
    const uploadedImages = event.target.files;
    setImages(uploadedImages);
  };

  const navigate = useNavigate();

  const revertTransaction = async (row) => {
    const result = await Swal.fire({
      title: "Are you sure you want to revert this transaction?",
      text: `Transaction Id: ${row.txnId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    });

    if (result.isConfirmed) {
      const payload = {
        remarks: `Reverting Transaction number ${row.txnId}`,
        siteId: localStorage.getItem("siteId"),
        txnId: row.txnId,
      };

      const encryptedPayload = getEncryptPayload(payload, true);

      handleRevert(encryptedPayload);
    }
  };

  const statusName =
    nextActionList &&
    nextActionList?.filter((val) => val.stateId == row.permissionStateId);

  const handleClickIcon = (rowData) => {
    if (rowData) {
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };

  console.log(parentEle, showAllTransaction);

  return (
    <React.Fragment key={index}>
      <StyledTableRow
        style={{
          backgroundColor: getSameValue.color,
          pointerEvents:
            row?.balanceProcessStatus === "Processed" ? "all" : "none",
        }}
      >
        {headers &&
          headers?.map((ele, i) =>
            ele.name === " "
              ? parentEle?.items?.length > 0 &&
                index === 0 && (
                  <>
                    <StyledTableCell
                      rowSpan={spanRw}
                      align="center"
                      style={{
                        minWidth: +ele?.width,
                        width: +ele?.width,
                      }}
                    >
                      {filterStateList?.length > 0 &&
                      selectedStatus !== null ? (
                        <Checkbox
                          checked={parentEle.items.some((item) =>
                            SelectedTransactionIds.includes(item.id)
                          )}
                          onChange={(e) => {
                            handleCheckboxChange(e.target.checked, parentEle);
                          }}
                          color="primary"
                        />
                      ) : (
                        // ""
                        <Stack direction="row">
                          {row?.isReverted === true ||
                          row?.isCancledTransaction === true ? (
                            <span style={{ width: "100%" }}>&nbsp;</span>
                          ) : (
                            <Tooltip title={"Transaction Revert"}>
                              <IconButton
                                onClick={() => {
                                  setOpenAlertDialog(true);
                                  setRevertPayload({
                                    row,
                                    parentEle,
                                    showAllTransaction,
                                    site,
                                  });
                                }}
                              >
                                <SettingsBackupRestoreIcon
                                  sx={{ color: themecolor }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!row?.isInterSite &&
                            checkModuleExist(endPoints, "Copy Transaction") && (
                              <IconButton
                                onClick={() =>
                                  navigate("/addTransaction", {
                                    state: {
                                      data: row,
                                      parentEle: parentEle,
                                      site: site,
                                      collapse: showAllTransaction,
                                      type: "copy",
                                    },
                                  })
                                }
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            )}
                        </Stack>
                      )}
                    </StyledTableCell>
                  </>
                )
              : ele.name === "S.NO"
              ? parentEle?.items?.length > 0 &&
                index === 0 && (
                  <StyledTableCell
                    rowSpan={spanRw}
                    style={{
                      minWidth: +ele?.width,
                      width: +ele?.width,
                      // textAlign: "center"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        // style={{
                        //   textAlign: row?.childList?.length > 0 ? "justify" : null
                        // }}
                      >
                        {count}
                        {row?.childList?.length > 0 ? (
                          <IconButton
                            aria-label="expand rowData"
                            size="small"
                            onClick={() => handleClickIcon(row)}
                          >
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        ) : null}
                      </Typography>
                    </div>
                  </StyledTableCell>
                )
              : ""
          )}

        {headers &&
          headers?.map((ele, i) =>
            ele.name === "Transaction Date" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: +ele?.width,
                  width: +ele?.width,
                }}
              >
                {moment(row?.date).format("DD-MM-YYYY, hh:mm A")}
              </StyledTableCell>
            ) : ele.name === "Txn Id" && ele.isShow ? (
              <StyledTableCell
                style={{
                  cursor: "pointer",
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
                onClick={() =>
                  navigate("/addTransaction", {
                    state: {
                      id: row?.txnId,
                      site: site,
                      path: "daybook",
                    },
                  })
                }
              >
                <Box>
                  <Editedtext
                    text={row?.txnId}
                    num={24}
                    textColor="blue"
                    fWaight={700}
                  />
                </Box>
              </StyledTableCell>
            ) : ele.name === "Ref. Id" && ele.isShow ? (
              <StyledTableCell
                style={{
                  cursor: "pointer",
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
                onClick={() =>
                  row?.cancledTxnId &&
                  navigate("/addTransaction", {
                    state: {
                      id: row?.cancledTxnId,
                      site: site,
                      path: "daybook",
                    },
                  })
                }
              >
                <Box>
                  {row?.isCancledTransaction && (
                    <IconButton>
                      <KeyboardBackspaceIcon
                        sx={{ fontSize: "13px", color: "#000000" }}
                      />
                    </IconButton>
                  )}
                  {row?.isReverted && (
                    <IconButton>
                      <EastIcon sx={{ fontSize: "13px", color: "#000000" }} />
                    </IconButton>
                  )}
                  <Editedtext
                    text={row?.cancledTxnId}
                    num={24}
                    textColor="blue"
                    fWaight={700}
                  />
                </Box>
              </StyledTableCell>
            ) : ele.name === "Debit Account" && ele.isShow ? (
              <>
                {showAllTransaction &&
                  parentEle.sourceAccountIdRowSpanSide === true &&
                  index === 0 && (
                    <StyledTableCell
                      style={{
                        minWidth: `${ele?.width}px`,
                        width: +ele?.width,
                      }}
                      rowSpan={
                        showAllTransaction &&
                        parentEle.sourceAccountIdRowSpanSide === true &&
                        parentEle.sourceAccountIdRowSpan + 1
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          checkPermissionExist(
                            endPoints,
                            singleTransactionGetUrl
                          ) &&
                            navigate("/generalLedger", {
                              state: {
                                id: row?.sourceAccountId,
                                name: row?.sourceAccountName,
                                code: row?.sourceAccountCode,
                                backPath: "daybook",
                                balance: row?.sourceAccountClosingBalance,
                                balanceType:
                                  row?.sourceAccountClosingBalanceType,
                              },
                            });
                        }}
                      >
                        {row?.sourceAccountName != "temp" &&
                        row?.sourceAccountName != "Temp" ? (
                          <div>
                            <Editedtext
                              text={row?.sourceAccountName}
                              num={30}
                            />
                            {!row?.isTemporary &&
                              row?.originalSourceAccountName && (
                                <span>
                                  ({row?.originalSourceAccountName || ""})
                                </span>
                              )}
                            {/* <p>{row?.sourceAccountName}</p> */}
                            <p style={{ color: "blue" }}>
                              {"("}
                              {row?.sourceAccountCode}
                              {")"}
                            </p>
                          </div>
                        ) : (
                          <>
                            -
                            {!row?.isTemporary &&
                              row?.originalSourceAccountName && (
                                <span>
                                  ({row?.originalSourceAccountName || ""})
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                {showAllTransaction &&
                  parentEle.sourceAccountIdRowSpanSide === null && (
                    <StyledTableCell
                      style={{
                        minWidth: `${ele?.width}px`,
                        width: +ele?.width,
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          checkPermissionExist(
                            endPoints,
                            singleTransactionGetUrl
                          ) &&
                            navigate("/generalLedger", {
                              state: {
                                id: row?.sourceAccountId,
                                name: row?.sourceAccountName,
                                code: row?.sourceAccountCode,
                                backPath: "daybook",
                                balance: row?.sourceAccountClosingBalance,
                                balanceType:
                                  row?.sourceAccountClosingBalanceType,
                              },
                            });
                        }}
                      >
                        {row?.sourceAccountName != "temp" &&
                        row?.sourceAccountName != "Temp" ? (
                          <div>
                            <Editedtext
                              text={row?.sourceAccountName}
                              num={30}
                            />
                            {!row?.isTemporary &&
                              row?.originalSourceAccountName && (
                                <span>
                                  ({row?.originalSourceAccountName || ""})
                                </span>
                              )}
                            {/* <p>{row?.sourceAccountName}</p> */}
                            <p style={{ color: "blue" }}>
                              {"("}
                              {row?.sourceAccountCode}
                              {")"}
                            </p>
                          </div>
                        ) : (
                          <>
                            -
                            {!row?.isTemporary &&
                              row?.originalSourceAccountName && (
                                <span>
                                  ({row?.originalSourceAccountName || ""})
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                {!showAllTransaction && (
                  <StyledTableCell
                    style={{
                      minWidth: `${ele?.width}px`,
                      width: +ele?.width,
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        checkPermissionExist(
                          endPoints,
                          singleTransactionGetUrl
                        ) &&
                          navigate("/generalLedger", {
                            state: {
                              id: row?.sourceAccountId,
                              name: row?.sourceAccountName,
                              code: row?.sourceAccountCode,
                              backPath: "daybook",
                              balance: row?.sourceAccountClosingBalance,
                              balanceType: row?.sourceAccountClosingBalanceType,
                            },
                          });
                      }}
                    >
                      {row?.sourceAccountName != "temp" &&
                      row?.sourceAccountName != "Temp" ? (
                        <div>
                          <Editedtext text={row?.sourceAccountName} num={30} />
                          {!row?.isTemporary &&
                            row?.originalSourceAccountName && (
                              <span>
                                ({row?.originalSourceAccountName || ""})
                              </span>
                            )}
                          {/* <p>{row?.sourceAccountName}</p> */}
                          <p style={{ color: "blue" }}>
                            {"("}
                            {row?.sourceAccountCode}
                            {")"}
                          </p>
                        </div>
                      ) : (
                        <>
                          -
                          {!row?.isTemporary &&
                            row?.originalSourceAccountName && (
                              <span>
                                ({row?.originalSourceAccountName || ""})
                              </span>
                            )}
                        </>
                      )}
                    </div>
                  </StyledTableCell>
                )}
              </>
            ) : ele.name === "Credit Account" && ele.isShow ? (
              <>
                {showAllTransaction &&
                  parentEle.targetAccountIdRowSpanSide === true &&
                  index === 0 && (
                    <StyledTableCell
                      style={{
                        minWidth: +ele?.width,
                        width: +ele?.width,
                      }}
                      rowSpan={
                        showAllTransaction &&
                        parentEle.targetAccountIdRowSpanSide === true &&
                        parentEle.targetAccountIdRowSpan + 1
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          checkPermissionExist(
                            endPoints,
                            singleTransactionGetUrl
                          ) &&
                            navigate("/generalLedger", {
                              state: {
                                id: row?.targetAccountId,
                                name: row?.targetAccountName,
                                code: row?.targetAccountCode,
                                backPath: "daybook",
                                balance: row?.targetAccountClosingBalance,
                                balanceType:
                                  row?.targetAccountClosingBalanceType,
                              },
                            });
                        }}
                      >
                        {row?.targetAccountName != "temp" &&
                        row?.targetAccountName != "Temp" ? (
                          <div>
                            {" "}
                            <Editedtext
                              text={row?.targetAccountName}
                              num={30}
                            />
                            {!row?.isTemporary &&
                              row?.originalTargetAccountName && (
                                <span>
                                  ({row?.originalTargetAccountName || ""})
                                </span>
                              )}
                            {/* <p>{row?.targetAccountName}</p> */}
                            <p style={{ color: "blue" }}>
                              {"("}
                              {row?.targetAccountCode}
                              {")"}
                            </p>
                          </div>
                        ) : (
                          <>
                            -
                            {!row?.isTemporary &&
                              row?.originalTargetAccountName && (
                                <span>
                                  ({row?.originalTargetAccountName || ""})
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                {showAllTransaction &&
                  parentEle.targetAccountIdRowSpanSide === null && (
                    <StyledTableCell
                      style={{
                        minWidth: +ele?.width,
                        width: +ele?.width,
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          checkPermissionExist(
                            endPoints,
                            singleTransactionGetUrl
                          ) &&
                            navigate("/generalLedger", {
                              state: {
                                id: row?.targetAccountId,
                                name: row?.targetAccountName,
                                code: row?.targetAccountCode,
                                backPath: "daybook",
                                balance: row?.targetAccountClosingBalance,
                                balanceType:
                                  row?.targetAccountClosingBalanceType,
                              },
                            });
                        }}
                      >
                        {row?.targetAccountName != "temp" &&
                        row?.targetAccountName != "Temp" ? (
                          <div>
                            {" "}
                            <Editedtext
                              text={row?.targetAccountName}
                              num={30}
                            />
                            {!row?.isTemporary &&
                              row?.originalTargetAccountName && (
                                <span>
                                  ({row?.originalTargetAccountName || ""})
                                </span>
                              )}
                            {/* <p>{row?.targetAccountName}</p> */}
                            <p style={{ color: "blue" }}>
                              {"("}
                              {row?.targetAccountCode}
                              {")"}
                            </p>
                          </div>
                        ) : (
                          <>
                            -
                            {!row?.isTemporary &&
                              row?.originalTargetAccountName && (
                                <span>
                                  ({row?.originalTargetAccountName || ""})
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                {!showAllTransaction && (
                  <StyledTableCell
                    style={{
                      minWidth: +ele?.width,
                      width: +ele?.width,
                    }}
                    // rowSpan={
                    //   showAllTransaction &&
                    //   parentEle.targetAccountIdRowSpanSide === true &&
                    //   parentEle.targetAccountIdRowSpan
                    // }
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        checkPermissionExist(
                          endPoints,
                          singleTransactionGetUrl
                        ) &&
                          navigate("/generalLedger", {
                            state: {
                              id: row?.targetAccountId,
                              name: row?.targetAccountName,
                              code: row?.targetAccountCode,
                              backPath: "daybook",
                              balance: row?.targetAccountClosingBalance,
                              balanceType: row?.targetAccountClosingBalanceType,
                            },
                          });
                      }}
                    >
                      {row?.targetAccountName != "temp" &&
                      row?.targetAccountName != "Temp" ? (
                        <div>
                          {" "}
                          <Editedtext text={row?.targetAccountName} num={30} />
                          {!row?.isTemporary &&
                            row?.originalTargetAccountName && (
                              <span>
                                ({row?.originalTargetAccountName || ""})
                              </span>
                            )}
                          {/* <p>{row?.targetAccountName}</p> */}
                          <p style={{ color: "blue" }}>
                            {"("}
                            {row?.targetAccountCode}
                            {")"}
                          </p>
                        </div>
                      ) : (
                        <>
                          -
                          {!row?.isTemporary &&
                            row?.originalTargetAccountName && (
                              <span>
                                ({row?.originalTargetAccountName || ""})
                              </span>
                            )}
                        </>
                      )}
                    </div>
                  </StyledTableCell>
                )}
              </>
            ) : ele.name === "Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  textAlign: "end",
                  width: +ele?.width,
                }}
              >
                {<AmountInfo amount={row?.amount} color="#000000de" />}
              </StyledTableCell>
            ) : ele.name === "Voucher" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                <Typography
                  sx={{ maxWidth: "200px", overflowWrap: "break-word" }}
                >
                  {row?.voucherTemplateId &&
                  voucherList &&
                  voucherList.length > 0
                    ? voucherList.find(
                        (val) => val.id === row.voucherTemplateId
                      )?.customFormType
                    : "-"}
                </Typography>
              </StyledTableCell>
            ) : ele.name === "Created On" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {moment(row?.createdOn).format("DD-MM-YYYY, hh:mm A")}
              </StyledTableCell>
            ) : ele.name === "Created By" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {row?.createdByName ? (
                  <Editedtext text={row?.createdByName} num="25" />
                ) : (
                  "-"
                )}
              </StyledTableCell>
            ) : ele.name === "Status" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {statusName?.[0]?.name}
              </StyledTableCell>
            ) : ele.name === "Account Code" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.accountCodeName || "-"}
              </StyledTableCell>
            ) : ele.name === "Transaction Type" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.accountTransactionTypeName || "-"}
              </StyledTableCell>
            ) : ele.name === "Invoice No" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.invoiceNo || "-"}
              </StyledTableCell>
            ) : ele.name === "Due Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "right",
                }}
              >
                <AmountInfo amount={row?.dueAmount} color="#000000de" />
              </StyledTableCell>
            ) : ele.name === "Remaining Settled Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "right",
                }}
              >
                <AmountInfo
                  amount={row?.remainingSettledAmount}
                  color="#000000de"
                />
              </StyledTableCell>
            ) : ele.name === "Over Due Days" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.overDueDays || 0}
              </StyledTableCell>
            ) : ele.name === "Over Due Count" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.overDueDaysCount || 0}
              </StyledTableCell>
            ) : ele.name === "Payment Status" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                  fontWeight: "bold",
                  color:
                    row?.paymentStatus === "PENDING"
                      ? "red"
                      : row?.paymentStatus === "PARTIAL"
                      ? "#ff7905"
                      : "green",
                }}
              >
                {row?.paymentStatus || "-"}
              </StyledTableCell>
            ) : ele.name === "CGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isCgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "IGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isIgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "SGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isSgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "Total Amount" && ele.isShow ? (
              parentEle?.items?.length > 0 &&
              index === 0 && (
                <StyledTableCell
                  rowSpan={spanRw}
                  align="right"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  <AmountInfo
                    amount={parentEle?.totalAmount}
                    color="#000000de"
                    fontWeight="700"
                  />
                </StyledTableCell>
              )
            ) : ele.name === "E-Invoice" && ele.isShow ? (
              parentEle?.items?.length > 0 &&
              index === 0 && (
                <StyledTableCell
                  rowSpan={spanRw}
                  style={{
                    minWidth: `${ele?.width}px`,
                    width: +ele?.width,
                    textAlign: "center",
                  }}
                >
                  {row?.isInvoiceGenerated ? (
                    // <RemoveRedEyeIcon
                    //   sx={{ color: "green", cursor: "pointer" }}
                    //   onClick={(e) => handleClickOpenDialog(row)}
                    // />
                    <Typography
                      sx={{
                        color: "green",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                    >
                      {"Generated"}
                    </Typography>
                  ) : (
                    // <AddIcon
                    //   sx={{ color: "blue", cursor: "pointer" }}
                    //   onClick={(e) => handleGenerate(row)}
                    // />
                    <Typography
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                    >
                      {"Not Generated"}
                    </Typography>
                  )}
                </StyledTableCell>
              )
            ) : row.hasOwnProperty(ele?.alias) && ele.isShow ? (
              <StyledTableCell
                key={ele.alias}
                style={{
                  minWidth: 250,
                  textAlign: "right",
                }}
              >
                <span>
                  <AmountInfo amount={row[ele?.alias]} />
                </span>
              </StyledTableCell>
            ) : (
              ""
            )
          )}
      </StyledTableRow>
      {/* Collapsible table to render the childList --->>> ChildList contains the data.items info without first indexing items info, and the first index is the parent of the childList */}
      {!showAllTransaction ? (
        <StyledTableRow
          style={{
            pointerEvents:
              row?.balanceProcessStatus === "Processed" ? "all" : "none",
          }}
        >
          <StyledTableCell
            colSpan={headers.length > 0 ? headers.length : 15}
            sx={{
              padding: "0px !important",
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases table">
                <TableBody>
                  {row &&
                    row?.childList &&
                    row?.childList.length > 0 &&
                    row?.childList.map((row, index) => (
                      <>
                        <StyledTableRow
                          style={{ backgroundColor: getSameValue.color }}
                        >
                          {headers &&
                            headers?.map((ele, i) =>
                              ele.name === " " ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                ></StyledTableCell>
                              ) : ele.name === "S.NO" ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  ></div>
                                </StyledTableCell>
                              ) : (
                                ""
                              )
                            )}

                          {headers &&
                            headers?.map((ele, i) =>
                              ele.name === "Transaction Date" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                >
                                  {moment(row?.date).format(
                                    "DD-MM-YYYY, hh:mm A"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Txn Id" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    cursor: "pointer",
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  onClick={() =>
                                    navigate("/addTransaction", {
                                      state: {
                                        id: row?.txnId,
                                        site: site,
                                        path: "daybook",
                                      },
                                    })
                                  }
                                >
                                  <Box>
                                    <Editedtext
                                      text={row?.txnId}
                                      num={24}
                                      textColor="blue"
                                      fWaight={700}
                                    />
                                  </Box>
                                </StyledTableCell>
                              ) : ele.name === "Ref. Id" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    cursor: "pointer",
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  onClick={() =>
                                    row?.cancledTxnId &&
                                    navigate("/addTransaction", {
                                      state: {
                                        id: row?.cancledTxnId,
                                        site: site,
                                        path: "daybook",
                                      },
                                    })
                                  }
                                >
                                  <Box>
                                    {row?.isCancledTransaction && (
                                      <IconButton>
                                        <KeyboardBackspaceIcon
                                          sx={{
                                            fontSize: "13px",
                                            color: "#000000",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {row?.isReverted && (
                                      <IconButton>
                                        <EastIcon
                                          sx={{
                                            fontSize: "13px",
                                            color: "#000000",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    <Editedtext
                                      text={row?.cancledTxnId}
                                      num={24}
                                      textColor="blue"
                                      fWaight={700}
                                    />
                                  </Box>
                                </StyledTableCell>
                              ) : ele.name === "Debit Account" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {parentEle.sourceAccountIdRowSpanSide ===
                                  true ? (
                                    ""
                                  ) : (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        checkPermissionExist(
                                          endPoints,
                                          singleTransactionGetUrl
                                        ) &&
                                          navigate("/generalLedger", {
                                            state: {
                                              id: row?.sourceAccountId,
                                              name: row?.sourceAccountName,
                                              code: row?.sourceAccountCode,
                                              backPath: "daybook",
                                              balance:
                                                row?.sourceAccountClosingBalance,
                                              balanceType:
                                                row?.sourceAccountClosingBalanceType,
                                            },
                                          });
                                      }}
                                    >
                                      {row?.sourceAccountName != "temp" &&
                                      row?.sourceAccountName != "Temp" ? (
                                        <div>
                                          <Editedtext
                                            text={row?.sourceAccountName}
                                            num={30}
                                          />
                                          {/* <p>{row?.sourceAccountName}</p> */}
                                          {!row?.isTemporary &&
                                            row?.originalSourceAccountName && (
                                              <span>
                                                (
                                                {row?.originalSourceAccountName ||
                                                  ""}
                                                )
                                              </span>
                                            )}
                                          <p style={{ color: "blue" }}>
                                            {"("}
                                            {row?.sourceAccountCode}
                                            {")"}
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          -
                                          {!row?.isTemporary &&
                                            row?.originalSourceAccountName && (
                                              <span>
                                                (
                                                {row?.originalSourceAccountName ||
                                                  ""}
                                                )
                                              </span>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Credit Account" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {parentEle.targetAccountIdRowSpanSide ===
                                  true ? (
                                    ""
                                  ) : (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        checkPermissionExist(
                                          endPoints,
                                          singleTransactionGetUrl
                                        ) &&
                                          navigate("/generalLedger", {
                                            state: {
                                              id: row?.targetAccountId,
                                              name: row?.targetAccountName,
                                              code: row?.targetAccountCode,
                                              backPath: "daybook",
                                              balance:
                                                row?.targetAccountClosingBalance,
                                              balanceType:
                                                row?.targetAccountClosingBalanceType,
                                            },
                                          });
                                      }}
                                    >
                                      {row?.targetAccountName != "temp" &&
                                      row?.targetAccountName != "Temp" ? (
                                        <div>
                                          {" "}
                                          <Editedtext
                                            text={row?.targetAccountName}
                                            num={30}
                                          />
                                          {/* <p>{row?.targetAccountName}</p> */}
                                          {!row?.isTemporary &&
                                            row?.originalTargetAccountName && (
                                              <span>
                                                (
                                                {row?.originalTargetAccountName ||
                                                  ""}
                                                )
                                              </span>
                                            )}
                                          <p style={{ color: "blue" }}>
                                            {"("}
                                            {row?.targetAccountCode}
                                            {")"}
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          -
                                          {!row?.isTemporary &&
                                            row?.originalTargetAccountName && (
                                              <span>
                                                (
                                                {row?.originalTargetAccountName ||
                                                  ""}
                                                )
                                              </span>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    textAlign: "end",
                                    width: +ele?.width,
                                  }}
                                >
                                  {
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  }
                                </StyledTableCell>
                              ) : ele.name === "Voucher" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: "290px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      maxWidth: "200px",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {row?.voucherTemplateId &&
                                    voucherList &&
                                    voucherList.length > 0
                                      ? voucherList.find(
                                          (val) =>
                                            val.id === row.voucherTemplateId
                                        )?.customFormType
                                      : "-"}
                                  </Typography>
                                </StyledTableCell>
                              ) : ele.name === "Created On" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {moment(row?.createdOn).format(
                                    "DD-MM-YYYY, hh:mm A"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Created By" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {row?.createdByName ? (
                                    <Editedtext
                                      text={row?.createdByName}
                                      num="25"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Status" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {statusName?.[0]?.name}
                                </StyledTableCell>
                              ) : ele.name === "Account Code" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.accountCodeName || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Transaction Type" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.accountTransactionTypeName || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Invoice No" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.invoiceNo || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Due Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "right",
                                  }}
                                >
                                  <AmountInfo
                                    amount={row?.dueAmount}
                                    color="#000000de"
                                  />
                                </StyledTableCell>
                              ) : ele.name === "Remaining Settled Amount" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "right",
                                  }}
                                >
                                  <AmountInfo
                                    amount={row?.remainingSettledAmount}
                                    color="#000000de"
                                  />
                                </StyledTableCell>
                              ) : ele.name === "Over Due Days" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.overDueDays || 0}
                                </StyledTableCell>
                              ) : ele.name === "Over Due Count" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.overDueDaysCount || 0}
                                </StyledTableCell>
                              ) : ele.name === "Payment Status" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color:
                                      row?.paymentStatus === "PENDING"
                                        ? "red"
                                        : row?.paymentStatus === "PARTIAL"
                                        ? "#ff7905"
                                        : "green",
                                  }}
                                >
                                  {row?.paymentStatus || "-"}
                                </StyledTableCell>
                              ) : ele.name === "CGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isCgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "IGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isIgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "SGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isSgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Total Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  colSpan={1}
                                ></StyledTableCell>
                              ) : ele.name === "E-Invoice" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="center"
                                  colSpan={1}
                                ></StyledTableCell>
                              ) : row.hasOwnProperty(ele?.alias) &&
                                ele.isShow ? (
                                <StyledTableCell
                                  key={ele.alias}
                                  style={{
                                    minWidth: 250,
                                    textAlign: "right",
                                  }}
                                >
                                  <span>
                                    <AmountInfo amount={row[ele?.alias]} />
                                  </span>
                                </StyledTableCell>
                              ) : (
                                ""
                              )
                            )}
                        </StyledTableRow>

                        {/* <StyledTableRow
                          style={{ backgroundColor: getSameValue.color }}
                        >
                          {filteredColumns &&
                            filteredColumns.length > 0 &&
                            filteredColumns.map((column) => (
                              <StyledTableCell
                                width={"250px"}
                                style={{ textAlign: "right" }}
                              >
                                <span>
                                  <AmountInfo amount={row[column?.alias]} />
                                </span>
                              </StyledTableCell>
                            ))}
                        </StyledTableRow> */}
                      </>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      ) : null}
    </React.Fragment>
  );
}

export default function TransactionTable({
  data,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,
  mainTransactionData,
  voucherList,
  nextActionList,
  selectedStatus,
  filterStateList,
  handleCheckboxChange,
  path,
  SelectedTransactionIds,
  showAllTransaction,
  selectedColumns,
  headerConfig,
  successAlert,
  errorAlert,
  setLoading,
  getTransactionList,
}) {
  const onPageChange = (event, value) => setPageNo(value);
  const [dataIndexRendering, setDataIndexRendering] = React.useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [revertPayload, setRevertPayload] = useState({
    row: null,
    parentEle: null,
    showAllTransaction: null,
    site: null,
  });

  const [opendia, setOpendia] = React.useState(false);
  const [invoiceDataSrting, setInvoiceDataString] = React.useState(null);

  const handleClickOpen = () => {
    setOpenAlertDialog(true);
  };

  const filteredColumns =
    selectedColumns &&
    selectedColumns.length > 0 &&
    selectedColumns.filter((val) => val.alias != "all");

  let currentVal;
  let currentCondition = true;
  let rowSpanCount = 0;
  const getSameValue = (val) => {
    if (currentVal) {
      if (currentVal !== val?.txnId) {
        currentVal = val?.txnId;
        currentCondition = !currentCondition;
        rowSpanCount = 0;
      }
    } else {
      currentVal = val?.txnId;
    }
    // if (currentCondition) {
    //   rowSpanCount += 1;
    // } else {
    //   rowSpanCount += 1;
    // }
    const styleData = {
      color: currentCondition ? "#dddddd" : "#fff",
      rowSpan: rowSpanCount,
    };
    // return currentCondition ? "#7aaed3" : "#959595";
    return styleData;
  };

  let counts = 0;

  const calculateRowSpans = (data) => {
    const calculateSpans = (parentArr, key) => {
      for (let i = 0; i < parentArr.length; i++) {
        const ele = parentArr[i];
        ele[`${key}RowSpan`] = 0;
        ele[`${key}RowSpanSide`] = null;
        let lastValue = null;
        let rowSpan = 0;
        ele.items.forEach((childArr) => {
          if (lastValue === childArr[key]) {
            rowSpan++;
            ele[`${key}RowSpan`] = rowSpan;
            ele[`${key}RowSpanSide`] = true;
            return;
          } else {
            if (lastValue !== null && lastValue !== childArr[key]) {
              // delete ele[`${key}RowSpan`];
              ele[`${key}RowSpan`] = 0;
              // delete ele[`${key}RowSpanSide`];
              ele[`${key}RowSpanSide`] = null;
            }
            lastValue = ele.items[0][key];
          }
        });
      }
    };

    const updatedData = [...data];

    calculateSpans(updatedData, "sourceAccountId");
    calculateSpans(updatedData, "targetAccountId");

    return updatedData;
  };

  const memoizedRowSpans = useMemo(
    () => calculateRowSpans(mainTransactionData),
    [mainTransactionData]
  );

  React.useEffect(() => {
    const mainTransaction = memoizedRowSpans.map((ele, index) => {
      const childList = [];
      for (let i = 1; i < ele.items.length; i++) {
        childList.push(ele.items[i]);
      }
      return {
        ...ele,
        items: [
          {
            ...ele.items[0],
            childList: childList,
          },
        ],
      };
    });
    setDataIndexRendering(mainTransaction);
  }, [memoizedRowSpans]);

  const cancelTransactionHandle = async (row, parentData, isCollapse, site) => {
    setLoading(true);
    const multiTransactionArray = [];

    if (isCollapse) {
      for (let i = 0; i < parentData?.items?.length; i++) {
        const ele = parentData?.items[i];
        multiTransactionArray.push({
          isTemporary: ele?.isTemporary,
          amount: ele?.amount,
          sourceAccountId: ele?.targetAccountId, // target account id
          targetAccountId: ele?.sourceAccountId, // source account id
          key: ele?.targetAccount?.key,
          voucherNo: ele?.invoiceNo,
          sourceAccountNarration: ele?.targetAccountNarration,
          targetAccountNarration: ele?.sourceAccountNarration,
        });
      }
    } else {
      multiTransactionArray.push({
        isTemporary: row?.isTemporary,
        amount: row?.amount,
        sourceAccountId: row?.targetAccountId, // target account id
        targetAccountId: row?.sourceAccountId, // source account id
        key: row?.targetAccount?.key,
        voucherNo: row?.invoiceNo,
        sourceAccountNarration: row?.targetAccountNarration,
        targetAccountNarration: row?.sourceAccountNarration,
      });
      if (row?.childList?.length > 0) {
        for (let i = 0; i < row?.childList?.length; i++) {
          const ele = row?.childList[i];
          multiTransactionArray.push({
            isTemporary: ele?.isTemporary,
            amount: ele?.amount,
            sourceAccountId: ele?.targetAccountId, // target account id
            targetAccountId: ele?.sourceAccountId, // source account id
            key: ele?.targetAccount?.key,
            voucherNo: ele?.invoiceNo,
            sourceAccountNarration: ele?.targetAccountNarration,
            targetAccountNarration: ele?.sourceAccountNarration,
          });
        }
      }
    }
    const payload = {
      remarks: row?.remarks || null,
      companyId: localStorage.getItem("companyId"),
      transactionDate: row?.date,
      siteId: localStorage.getItem("siteId"),
      transactionInfo: multiTransactionArray,
      userId: localStorage.getItem("userId") || 14000,
      financialYearId: localStorage.getItem("financialYear")?.id,
      voucherTemplateTypeId: row?.voucherTemplateId,
      formDataForInput: row?.jsonData,
      invoiceNo: row?.invoiceNo,
      permissionStateId: row?.permissionStateId,
      isAvailable: row?.isAvailable,
      isApprovedBalance: true,
      accountCodeId: row?.accountCodeId,
      accountTransactionTypeId: row?.accountTransactionTypeId,
      paymentTransactionIds: row?.paymentTransactionIds,
      voucherTempalteCategoryId: row?.voucherTemplateCategoryId,
      isCancledTransaction: true,
      cancledMasterTxnId: row?.txnId,
    };
    const encryptedPayload = getEncryptPayload(payload, true);
    let key = payload?.transactionInfo[0]?.key;

    try {
      const res = await userService.postData(
        addTransactionUrl,
        encryptedPayload,
        key
      );
      if (res && res.data && res.data.status === 200) {
        setLoading(false);
        successAlert(res?.data?.message || "Transaction reverted successfully");
        getTransactionList();
        setOpenAlertDialog(false);
      } else {
        setLoading(false);
        errorAlert(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      errorAlert("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = () => {
    const { row, parentEle, showAllTransaction, site } = revertPayload;
    cancelTransactionHandle(row, parentEle, showAllTransaction, site);
  };
  const handleClickOpenDialog = (row) => {
    setInvoiceDataString(row?.eInvoiceDetail || null);
    setOpendia(true);
  };
  const handleClose = () => {
    setOpendia(false);
  };
  const handleGenerate = async (row) => {
    console.log(row, "vahsn");
    try {
      const res = await userService.postData(
        `${generateInvoiceByTransactionId}?transactionId=${row?.txnId}`
      );
      if (res && res.data && res.data.status === 200) {
        setLoading(false);
        successAlert(res?.data?.message || "Generate E-Invoice successfully!");
        getTransactionList();
      } else {
        setLoading(false);
        errorAlert(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      errorAlert("Something went wrong");
    }
  };

  // console.log(memoizedRowSpans);
  // console.log(dataIndexRendering);

  return (
    <>
      <AlertDialog
        title={"Do you want to revert this transaction?"}
        submitTitle={"Yes"}
        cancelTitle={"No"}
        handleClickOpen={handleClickOpen}
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
        handleSubmit={handleSubmit}
      />
      <TableContainer
        component={Paper}
        style={{
          marginTop: 20,
          height: data && data.length > 0 && "calc(100vh - 263px)",
        }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headerConfig &&
                headerConfig.length > 0 &&
                headerConfig.map((column, columnIndex) =>
                  column.name === "S.NO" ? (
                    <React.Fragment>
                      <StyledTableCell
                        style={{
                          minWidth: +column?.width || 250,
                          textAlign: column?.textAlign || "left",
                        }}
                      >
                        {column.name}
                      </StyledTableCell>
                    </React.Fragment>
                  ) : (
                    column.name === " " && (
                      <React.Fragment>
                        <StyledTableCell
                          style={{
                            minWidth: +column?.width || 250,
                            textAlign: column?.textAlign || "left",
                          }}
                        ></StyledTableCell>
                      </React.Fragment>
                    )
                  )
                )}
              {headerConfig &&
                headerConfig.length > 0 &&
                headerConfig.map(
                  (column, columnIndex) =>
                    column.name !== "S.NO" &&
                    column.name !== " " &&
                    column.isShow && (
                      <React.Fragment>
                        <StyledTableCell
                          style={{
                            minWidth: +column?.width || 250,
                            textAlign: column?.textAlign || "right",
                          }}
                        >
                          {column.name}
                        </StyledTableCell>
                      </React.Fragment>
                    )
                )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!showAllTransaction
              ? dataIndexRendering &&
                dataIndexRendering.map((ele) => {
                  return ele.items.map((item, index) => {
                    const bgColor = getSameValue(item);
                    counts += 1;
                    return (
                      <Row
                        key={item.id}
                        row={item}
                        index={index}
                        parentInd={index}
                        count={counts}
                        // index={(pageNo - 1) * pageSize + index + 1}
                        handleRevert={handleRevert}
                        site={site}
                        getSameValue={bgColor}
                        parentEle={ele}
                        spanRw={ele.items.length}
                        voucherList={voucherList}
                        nextActionList={nextActionList}
                        selectedStatus={selectedStatus}
                        filterStateList={filterStateList}
                        handleCheckboxChange={handleCheckboxChange}
                        path={path}
                        isSelected={item?.isSelected}
                        SelectedTransactionIds={SelectedTransactionIds}
                        headers={headerConfig}
                        showAllTransaction={showAllTransaction}
                        filteredColumns={filteredColumns}
                        // cancelTransactionHandle={cancelTransactionHandle}
                        setOpenAlertDialog={setOpenAlertDialog}
                        setRevertPayload={setRevertPayload}
                        handleGenerate={handleGenerate}
                        handleClickOpenDialog={handleClickOpenDialog}
                      />
                    );
                  });
                })
              : memoizedRowSpans &&
                memoizedRowSpans.map((ele) => {
                  counts += 1;
                  return ele.items.map((item, index) => {
                    const bgColor = getSameValue(item);
                    return (
                      <Row
                        key={item.id}
                        row={item}
                        index={index}
                        parentInd={index}
                        count={counts}
                        // index={(pageNo - 1) * pageSize + index + 1}
                        handleRevert={handleRevert}
                        site={site}
                        getSameValue={bgColor}
                        parentEle={ele}
                        spanRw={ele.items.length}
                        voucherList={voucherList}
                        nextActionList={nextActionList}
                        selectedStatus={selectedStatus}
                        filterStateList={filterStateList}
                        handleCheckboxChange={handleCheckboxChange}
                        path={path}
                        isSelected={item?.isSelected}
                        SelectedTransactionIds={SelectedTransactionIds}
                        headers={headerConfig}
                        showAllTransaction={showAllTransaction}
                        filteredColumns={filteredColumns}
                        // cancelTransactionHandle={cancelTransactionHandle}
                        setOpenAlertDialog={setOpenAlertDialog}
                        setRevertPayload={setRevertPayload}
                        handleGenerate={handleGenerate}
                        handleClickOpenDialog={handleClickOpenDialog}
                      />
                    );
                  });
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length < 1 && <DataStatus />}
      {data && Array.isArray(data) && data.length > 0 && (
        <CommonPagination
          pageSize={pageSize}
          pageSizes={pageSizes}
          totalPages={totalPages}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          style={{ minWidth: 180, margin: "0  20px" }}
          label="Transaction Per Page"
        />
      )}
      {opendia && (
        <ShowEInvoice
          open={opendia}
          setOpen={setOpendia}
          invoiceDataSrting={invoiceDataSrting}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
