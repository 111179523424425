import { amountLength } from "../utils";

export const validationFunction = (amount, sourceAccount, targetAccount) => {
  let errors = {};
  if (!targetAccount) errors.targetAccount = true;
  if (!sourceAccount) errors.sourceAccount = true;
  if (!amount || amount.length > amountLength) errors.amount = true;
  if (sourceAccount && targetAccount && sourceAccount.id === targetAccount.id) {
    errors.targetAccount = true;
    errors.sourceAccount = true;
  }
  return errors;
};

export const headers = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Category" },
  { id: 3, name: "Amount" },
  // { id: 7, name: "Created On" },
  // { id: 8, name: "Created By" },
  // { id: 9, name: "Action" },
];

export const getAmountColor = (amount) => {
  return amount > 0 ? "red" : "green";
};
