import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPermissionExist } from "../utils";
import {
  accountGroupDropdownUrl,
  accountGroupDropdownUrlV2,
  accountGroupDropdownUrlVoucher,
  accountTypesV1Url,
  addTransactionUrl,
  getAllState,
  singleVoucherGetUrl,
  voucherConfigGetUrl,
  voucherConfigSaveUrl,
  voucherUdpateUrl
} from "../config";
import { userService } from "../userService";
import {
  Button,
  Paper,
  Container,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  IconButton,
  Box,
  Tooltip
} from "@mui/material";
import { styles } from "../Theme/Theme";
import { withStyles } from "@material-ui/core";
import { getEncryptPayload } from "../Network_Utility";
import { AlertBar } from "../Alert/AlertBar";
import { useSelector } from "react-redux";
import { disableSite } from "../features/actions/siteAction";
import { useDispatch } from "react-redux";
import { themecolor } from "../Navigation";
import { FcSettings } from "react-icons/fc";
import { filterAccountUrl } from "../config";
import ClearIcon from "@mui/icons-material/Clear";
import DragAndDropComponent from "./DynamicFields";
import Header from "../CommonComps/Header";
import Editor from "./Editor";
import TableCompoentModal from "./TableComponentModal";
import LoadingComponent from "../Loader";
import ConfigModal from "./ConfigModal";
import SearchIcon from "@mui/icons-material/Search";
import { AutoSizer, List } from "react-virtualized";
import {
  ChildAccountConfiguration,
  ConfigurationCheckFields,
  DetailsViewConfigurations,
  DueDataConfigurations,
  EInvoiceDocTypes,
  MandatoryCheck,
  TaggedFunction
} from "./CollapsableData";
import { v4 as uuidv4 } from "uuid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getAllAccountTypes } from "../features/actions/AccountTypeAction";
import { accountTypeConfigValidation, templateViewList } from "./util";
const customFieldIcon = require("../images/custom.png");

// const templateViewList = [
//   { id: 1, name: "Journal", alias: "JOURNAL" },
//   { id: 2, name: "Purchase", alias: "PURCHASE" },
//   { id: 3, name: "Sale", alias: "SALE" },
//   { id: 4, name: "Payment", alias: "PAYMENT" }
// ];

const EditTemplate = ({ classes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxState = useSelector((val) => val?.rootReducer);
  const endPoints = reduxState?.permissionReducer?.data;
  const siteList = reduxState?.sitesReducer?.data;
  const accountTypesState = reduxState.accountTypeReducer;
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  // ===================States=================//
  const [loading, setLoading] = useState(false);
  const [tempateName, setTemplateName] = useState(null);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [searchDebitAccounts, setSearchDebitAccounts] = useState([]);
  const [searchCreditAccounts, setSearchCreditAccounts] = useState([]);
  const [accGrps, setAccGrps] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [accountGroupsv2, setAccountGroupsv2] = useState([]);
  const [customErrors, setCustomErrors] = useState({});
  const [templateTypeId, setTemplateTypeId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [tableItem, setTableItem] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [editedIndex, seteditedIndex] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [configArrays, setConfigArrays] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [pageNo, setPageNo] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(1);
  const [configList, setConfigList] = useState([]);
  const [stateActionList, setStateActionList] = useState([]);
  const [nextState, setNextState] = useState(null);

  // ===========Templates Confuiguration state's========//
  const [selectTemplateView, setSelectTemplateView] = useState(null);
  const [showTemplateView, setTemplateView] = useState(false);

  const [overDueBoolean, setOverDueBoolean] = useState(false);
  const [accountCategory, setAccountCategory] = useState(false);
  const [overDueDays, setOverDueDays] = useState(0);
  const [checkboxError, setCheckboxError] = React.useState(false);
  const [radioError, setRadioError] = React.useState(false);

  const [taggedInvoice, setTaggedInvoice] = useState(false);

  const [invoiceOrNoteNumber, setInvoiceorNotNumber] = useState(false);
  const [accountCode, setAccountCode] = useState(false);
  const [transactionType, setTransactionType] = useState(false);

  const [sourceAccountChildTableList, setSourceAccountChildTableList] =
    useState([]);
  const [childConfigError, setChildConfigError] = useState({});
  const [accountTypes, setAccountTypes] = useState([]);
  const [targetAccountTypes, setTargetAccountTypes] = useState([]);
  const [sourceAccountType, setSourceAccountType] = useState(null);
  const [sourceAccountTypeChildList, setSourceAccountTypeChildList] =
    useState(null);

  const [sourceAccountTypeChildLists, setSourceAccountTypeChildLists] =
    useState([]);
  const [targetAccountType, setTargetAccountType] = useState(null);
  const [accountTypeChildLists, setAccountTypeChildLists] = useState([]);
  const [targetAccountTypeChildLists, setTargetAccountTypeChildLists] =
    useState([]);
  const [targetAccountTypeChildList, setTargetAccountTypeChildList] =
    useState(null);
  const [targetAccountChildTableList, setTargetAccountChildTableList] =
    useState([]);
  const [sourceExistData, setSourceExistData] = useState([]);
  const [targetExistData, setTargetExistData] = useState([]);
  const [permissionStateId, setPermissionStateId] = useState(null);

  const [eInvoiceGeneration, setEInvoiceGeneration] = React.useState(false);
  const [eInvoiceGenerationImmediate, setEInvoiceGenerationImmediate] =
    useState(false);
  const [invDocType, setInvDocType] = useState(null);

  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: ""
  });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success"
    });
    setLoading(false);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error"
    });
    setLoading(false);
  };

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  const closeConfigModal = () => setOpenConfigModal(false);

  //Function to edit created fields
  const openEditorFuntion = (index) => {
    setOpenEditor(true);
    seteditedIndex(index);
  };

  // Function to open table modal
  const setHandleTableComponent = (item) => {
    setOpenModal(true);
    setTableItem(item);
  };

  //Function to close created fields modal
  const closeEditor = () => setOpenEditor(false);

  // Function to close table modal
  const closeDialogBox = () => setOpenModal(false);

  // Function to fetch account groups
  // const getAccountGroup = async () => {
  //     // Prepare the payload object with tokenData
  //     let payload = {
  //         siteId: null
  //     };

  //     const encryptedPayload = getEncryptPayload(payload, true)

  //     try {

  //         // Send a POST request to the API endpoint with the payload
  //         const res = await userService.postData(accountGroupDropdownUrl, encryptedPayload);

  //         if (res && res.data && res.data.data && res.data.status === 200) {
  //             // If the response is successful, extract the data
  //             const { data } = res.data;

  //             // Update the accountGroups state with the retrieved data
  //             const filteredAccgroup = data && data.length > 0 && data.filter(val => val.name !== "All")
  //             const arrayOfGroup = []

  //             for (let i = 0; i < filteredAccgroup.length; i++) {
  //                 let obj = filteredAccgroup[i]
  //                 obj.siteName = siteList && siteList.find(val => val.id === filteredAccgroup[i].siteId)?.name
  //                 arrayOfGroup.push(obj)
  //             }
  //             setAccountGroups(arrayOfGroup);

  //             // setAccountGroups(filteredAccgroup);

  //         } else {
  //             // If the response is not successful or the data is missing, reset the accountGroups state and set enableAsync to true
  //             setAccountGroups([]);

  //         }
  //     } catch (error) {
  //         // Handle any errors that occur during the API request
  //         console.log("error", error);
  //     }
  // };

  const getAccountGroup = async () => {
    try {
      const payload = { siteId: null };
      const encryptedPayload = getEncryptPayload(payload, true);

      const res = await userService.postData(
        accountGroupDropdownUrlVoucher,
        encryptedPayload
      );
      // const res = await userService.postData(accountGroupDropdownUrlV2, encryptedPayload);

      if (res?.data?.status === 200) {
        const { data } = res.data;

        // const filteredAccgroup = (data || []).filter(val => val.name !== "All" && val.siteId != null);
        const filteredAccgroup = (data || []).filter(
          (val) => val.siteId != null
        );

        const arrayOfGroup = filteredAccgroup
          .map((group) => {
            const siteMatch = siteList.find((site) => site.id === group.siteId);
            if (siteMatch) {
              return {
                ...group,
                siteName: siteMatch.name
              };
            }
            return null;
          })
          .filter(Boolean);

        setAccountGroups(arrayOfGroup);
      } else {
        setAccountGroups([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTemplateDataById = async (id) => {
    const companyId = localStorage.getItem("companyId");
    const apiEndpoint = `${singleVoucherGetUrl}?companyId=${companyId}&templateTypeId=${id}`;

    try {
      setLoading(true);
      const response = await userService.getData(apiEndpoint);

      if (response && response.data && response.data.status === 200) {
        const {
          creditGroup = [],
          debitGroup = [],
          templateData,
          permissionStateId,
          voucherTempalteCategoryId,
          voucherCategoryType,
          isOverdueForDebit,
          defaultOverdueDays,
          taggedInvoice,
          isInvoiceMandatory,
          isAccountCode,
          isTransactionType,
          isInvoiceGenerationMandatory,
          isInvoiceGenerationImmediate,
          invDocType,
          sourceChildAccountTypeDtoList,
          targetChildAccountTypeDtoList
        } = response.data.data;

        setPermissionStateId(permissionStateId);
        const targetObj = [];
        for (let i = 0; i < targetChildAccountTypeDtoList.length; i++) {
          const ele = targetChildAccountTypeDtoList[i];
          targetObj.push(ele?.accountTypeId);
        }
        setTargetExistData(targetObj);
        const sourceObj = [];
        for (let i = 0; i < sourceChildAccountTypeDtoList.length; i++) {
          const ele = sourceChildAccountTypeDtoList[i];
          sourceObj.push(ele?.accountTypeId);
        }
        setSourceExistData(sourceObj);

        const filteredSourceArray = accountTypesState?.data?.filter(
          (item) => !sourceObj?.includes(item.id)
        );
        setAccountTypes(filteredSourceArray);
        const filteredTargetArray = accountTypesState?.data?.filter(
          (item) => !targetObj?.includes(item.id)
        );
        setTargetAccountTypes(filteredTargetArray);
        // console.log(filteredSourceArray, sourceObj)

        //       setAccountTypes(accountTypesState?.data);

        setTargetAccountChildTableList(
          targetChildAccountTypeDtoList?.map((ele) => {
            return {
              deletedData: false,
              locally: false,
              id: ele?.id,
              isActive: ele?.isActive,
              isNewData: false,
              type: { id: ele?.accountTypeId, name: ele?.accountTypeDto?.name },
              child: {
                id: ele?.childAccountTypeId,
                childAccountNamePrifixSubString:
                  ele?.childAccountTypeDto?.childAccountNamePrifixSubString,
                childAccountNameSufixSubString:
                  ele?.childAccountTypeDto?.childAccountNameSufixSubString
              }
            };
          })
        );

        setSourceAccountChildTableList(
          sourceChildAccountTypeDtoList?.map((ele) => {
            return {
              deletedData: false,
              locally: false,
              isNewData: false,
              id: ele?.id,
              isActive: ele?.isActive,
              type: { id: ele?.accountTypeId, name: ele?.accountTypeDto?.name },
              child: {
                id: ele?.childAccountTypeId,
                childAccountNamePrifixSubString:
                  ele?.childAccountTypeDto?.childAccountNamePrifixSubString,
                childAccountNameSufixSubString:
                  ele?.childAccountTypeDto?.childAccountNameSufixSubString
              }
            };
          })
        );

        const allGroups = [...creditGroup, ...debitGroup];
        const filteredGroups = allGroups.filter(
          (group) => group.siteId != null
        );

        const arrayOfGroup = filteredGroups
          .map((group) => {
            const siteMatch = siteList.find((site) => site.id === group.siteId);
            if (siteMatch) {
              return { ...group, siteName: siteMatch.name };
            }
            return null;
          })
          .filter(Boolean);

        setAccountGroups(arrayOfGroup);

        const addSiteName = (groups) => {
          return groups.map((group) => {
            const matchingAccountGroup = arrayOfGroup.find(
              (val) => val.id === group.id
            );
            return {
              ...group,
              siteName: matchingAccountGroup?.siteName || null
            };
          });
        };

        const arrayOfCreditGroup = addSiteName(creditGroup);
        const arrayOfDebitGroup = addSiteName(debitGroup);
        const voucherTemplate = templateViewList.filter(
          (ele) => ele.id === voucherTempalteCategoryId
        );

        setCreditAccounts(arrayOfCreditGroup);
        setDebitAccounts(arrayOfDebitGroup);
        setSearchDebitAccounts(arrayOfDebitGroup);
        setSearchCreditAccounts(arrayOfCreditGroup);
        setDynamicFields(templateData);
        setSelectTemplateView(voucherTemplate[0]);
        setOverDueBoolean(
          voucherCategoryType === "SALE_PURCHASE_NOTE" ? true : false
        );
        setAccountCategory(
          isOverdueForDebit === true &&
            voucherCategoryType === "SALE_PURCHASE_NOTE"
            ? "debit"
            : isOverdueForDebit === false &&
              voucherCategoryType === "SALE_PURCHASE_NOTE"
            ? "credit"
            : null
        );
        setOverDueDays(defaultOverdueDays || 0);
        setTaggedInvoice(
          isOverdueForDebit === true && voucherCategoryType === "PAYMENT"
            ? "debit"
            : isOverdueForDebit === false && voucherCategoryType === "PAYMENT"
            ? "credit"
            : null
        );
        setInvoiceorNotNumber(isInvoiceMandatory || false);
        setAccountCode(isAccountCode || false);
        setTransactionType(isTransactionType || false);
        setEInvoiceGeneration(isInvoiceGenerationMandatory || false);
        setEInvoiceGenerationImmediate(isInvoiceGenerationImmediate || false);
        setInvDocType(invDocType || null);

        successAlert(response.data.message);

        localStorage.removeItem("stateHistory");
      } else {
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      errorAlert(error?.message);
    } finally {
      setLoading(false);
    }
  };

  function hasDuplicateNames(arr) {
    const nameSet = new Set();
    for (const obj of arr) {
      if (nameSet.has(obj.label)) {
        return true; // Duplicate label found
      }
      nameSet.add(obj.label);
    }
    return false; // No duplicate names found
  }

  const handleUpdate = async () => {
    if (hasDuplicateNames(dynamicFields)) {
      errorAlert("Template contains fields with similar label.");

      return;
    }

    if (debitAccounts?.length === 0) {
      errorAlert("Debit group is required!!");
      return;
    }

    if (creditAccounts?.length === 0) {
      errorAlert("Credit group is required!!");
      return;
    }

    // if (!nextState) {
    //     setCustomErrors({ ...customErrors, nextState: true })
    //     return
    // }

    //     sourceAccountChildTableList
    // targetAccountChildTableList
    // debugger
    const newSourceData = [];
    const newTargetData = [];
    // const newSourceData = sourceAccountChildTableList?.filter(
    //   (ele) => ele?.locally === true && ele?.deletedData !== true
    // );
    // const newTargetData = targetAccountChildTableList?.filter(
    //   (ele) => ele?.locally !== true || ele?.deletedData !== true
    // );

    for (let i = 0; i < sourceAccountChildTableList.length; i++) {
      const element = sourceAccountChildTableList[i];
      if (element?.locally === true) {
        if (element?.deletedData !== true) {
          newSourceData.push(element);
        }
      } else {
        if (element?.deletedData === true && element?.locally !== true) {
          newSourceData.push(element);
        }
      }
    }

    for (let i = 0; i < targetAccountChildTableList.length; i++) {
      const element = targetAccountChildTableList[i];
      if (element?.locally === true) {
        if (element?.deletedData !== true) {
          newTargetData.push(element);
        }
      } else {
        if (element?.deletedData === true && element?.locally !== true) {
          newTargetData.push(element);
        }
      }
    }

    const newTargetDataIsTarget = newTargetData?.filter(
      (ele) => ele.isActive === true
    );
    const newSourceDataIsSource = newSourceData?.filter(
      (ele) => ele.isActive === true
    );

    const newTargetDataIsTargetTable = targetAccountChildTableList?.filter(
      (ele) => ele.isActive === true
    );
    const newSourceDataIsSourceTable = sourceAccountChildTableList?.filter(
      (ele) => ele.isActive === true
    );
    console.log(nextState);
    const payload = {
      siteId: localStorage.getItem("siteId"),
      companyId: localStorage.getItem("companyId"),
      templateTypeId: templateTypeId,
      customFormData: {
        templateData: dynamicFields
      },
      permissionStateId: nextState?.stateId || null,
      isAvailable: nextState?.isFinal || null,
      debitGroupIds:
        debitAccounts && debitAccounts.length > 0
          ? debitAccounts.map((val) => val.id)
          : [],
      creditGroupIds:
        creditAccounts && creditAccounts.length > 0
          ? creditAccounts.map((val) => val.id)
          : [],
      voucherCategoryType:
        overDueBoolean === true
          ? "SALE_PURCHASE_NOTE"
          : taggedInvoice
          ? "PAYMENT"
          : "OTHER",
      voucherTempalteCategoryId: selectTemplateView?.id || null,
      isOverdueForDebit:
        accountCategory === "debit" || taggedInvoice === "debit"
          ? true
          : accountCategory === "credit" || taggedInvoice === "credit"
          ? false
          : null,
      defaultOverdueDays: overDueDays > 0 ? overDueDays : null,
      // taggedInvoice: taggedInvoice || null,
      isInvoiceMandatory: invoiceOrNoteNumber || false,
      isAccountCode: accountCode || false,
      isTransactionType: transactionType || false,
      isSourceChildAccountType:
        newSourceDataIsSourceTable?.length > 0 ||
        newSourceDataIsSource?.length > 0
          ? true
          : false,
      isTargetChildAccountType:
        newTargetDataIsTargetTable?.length > 0 ||
        newTargetDataIsTarget?.length > 0
          ? true
          : false,
      sourceChildAccountTypeDtoList: newSourceData?.map((ele) => {
        return {
          customFormTypeId: templateTypeId,
          childAccountTypeId: ele?.child?.id,
          isActive: ele?.hasOwnProperty("isActive") ? ele?.isActive : true,
          createdBy: +localStorage.getItem("userId"),
          updatedBy: +localStorage.getItem("userId"),
          isForSource: true,
          accountTypeId: ele?.type?.id,
          id: ele.deletedData ? ele.id : null
        };
      }),
      targetChildAccountTypeDtoList: newTargetData?.map((ele) => {
        return {
          id: ele.deletedData ? ele.id : null,
          customFormTypeId: templateTypeId,
          childAccountTypeId: ele?.child?.id,
          isActive: ele?.hasOwnProperty("isActive") ? ele?.isActive : true,
          createdBy: +localStorage.getItem("userId"),
          updatedBy: +localStorage.getItem("userId"),
          isForSource: false,
          accountTypeId: ele?.type?.id
        };
      }),
      isInvoiceGenerationMandatory: eInvoiceGeneration || false,
      isInvoiceGenerationImmediate: eInvoiceGenerationImmediate || false,
      invDocType: invDocType || null
    };
    const encryptedPayload = getEncryptPayload(payload);

    try {
      setLoading(true);
      const response = await userService.postData(
        voucherUdpateUrl,
        encryptedPayload
      );

      if (response && response.data && response.data.status === 200) {
        successAlert(response?.data?.message);
        setTimeout(() => {
          navigate("/voucherTemplate");
        }, 1000);
      } else errorAlert(response?.data?.message);
    } catch (error) {
      console.log(error);
      errorAlert(error?.message);
    }
  };

  const handleAccountChange = (event, newValue, accountType) => {
    if (!newValue) return;

    const targetAccounts =
      accountType === "debit" ? creditAccounts : debitAccounts;

    const isPresent =
      targetAccounts && targetAccounts.some((val) => val.id === newValue.id);

    if (accountType === "debit") {
      setDebitAccounts([...debitAccounts, newValue]);
      setSearchDebitAccounts([...debitAccounts, newValue]);
    } else {
      setCreditAccounts([...creditAccounts, newValue]);
      setSearchCreditAccounts([...creditAccounts, newValue]);
    }
  };
  const getHistoryState =
    localStorage.getItem("stateHistory") &&
    JSON.parse(localStorage.getItem("stateHistory"));

  useEffect(() => {
    getAccountTypes();
  }, []);

  useEffect(() => {
    if (getHistoryState?.id) {
      getTemplateDataById(getHistoryState?.id);
      setTemplateTypeId(getHistoryState?.id);
      setTemplateName(getHistoryState?.name);
    }
    if (location && location.state && location.state.id) {
      getTemplateDataById(location?.state?.id);
      setTemplateTypeId(location?.state?.id);
      setTemplateName(location?.state?.name);
    }

    // if (
    //   location &&
    //   location.state &&
    //   location.state.id &&
    //   accountGroups &&
    //   accountGroups.length > 0
    // ) {
    //   getTemplateDataById(location?.state?.id);
    //   setTemplateTypeId(location?.state?.id);
    //   setTemplateName(location?.state?.name);
    // }
    //eslint-disable-next-line
  }, [location, getHistoryState]);

  const getAccountList = async (dt) => {
    const payload = {
      accountGroups: dt,
      pageNo,
      pageSize,
      siteId: siteList && siteList.find((val) => val.isHeadOffice)?.id
    };
    const response = await userService.postData(filterAccountUrl, payload);
    try {
      setLoading(true);
      if (response?.data?.status === 200) {
        const responseData = response.data.data?.data;
        const totalPages = response.data.data?.totalLength;

        if (responseData && responseData.length > 0) {
          setLoading(false);
          setOpenConfigModal(true);

          setTotalPages(Math.ceil(totalPages / pageSize));

          const accountsArray = [];

          for (let j = 0; j < responseData.length; j++) {
            const accountObj = { ...responseData[j], configKeys: [] };

            for (let i = 0; i < configList.length; i++) {
              if (responseData[j].id === configList[i].hoAccountId) {
                accountObj.configKeys.push(configList[i].dynamicKey);
              }
            }

            accountsArray.push(accountObj);
          }

          setAccounts(accountsArray.length > 0 ? accountsArray : responseData);
        } else {
          successAlert("No accounts mapped with the following account group");
        }
      } else {
        setLoading(false);
        errorAlert(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Function to handle config event
  const handleClick = (type) => {
    if (type === "debit") {
      setConfigArrays(debitAccounts);
      debitAccounts &&
        debitAccounts.length > 0 &&
        getAccountList(debitAccounts.map((val) => val.id));
    } else {
      setConfigArrays(creditAccounts);
      creditAccounts &&
        creditAccounts.length > 0 &&
        getAccountList(creditAccounts.map((val) => val.id));
    }
  };

  const handleAccounts = (index, value) => {
    const dt = [...accounts];
    dt[index].value = value;
    setAccounts(dt);
  };

  const handlAdd = async (index) => {
    const dt = accounts[index];

    if (!dt.value) {
      errorAlert("Provide key to proceed");
      return;
    }

    if (
      dt?.configKeys.length > 0 &&
      dt.configKeys.some((val) => val == dt.value.toLocaleUpperCase())
    ) {
      errorAlert("Key already exist.");
      return;
    }

    const payload = {
      accountVoucherId: templateTypeId,
      hoAccountId: dt?.id,
      dynamicKey: dt?.value.toLocaleUpperCase(),
      type: 1
    };
    const response = await userService.postData(voucherConfigSaveUrl, payload);
    if (response && response.data && response.data.status === 200) {
      successAlert(response?.data?.message);
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else errorAlert(response?.data?.message);
  };

  const getConfigKeys = async () => {
    let apiEndpoint = `${voucherConfigGetUrl}/${templateTypeId}`;
    try {
      const response = await userService.getData(apiEndpoint);
      if (response && response.data && response.data.status === 200) {
        setConfigList(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(disableSite(true));
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   templateTypeId && getConfigKeys();
  //   //eslint-disable-next-line
  // }, [templateTypeId]);

  useEffect(() => {
    // getAccountGroup();
    //eslint-disable-next-line
  }, [siteList]);

  const filterAccountGroups = (inputValue, type) => {
    if (!inputValue) {
      setAccGrps([]);
      return;
    }

    const filteredGrps = accountGroups.filter((val) =>
      val.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filteredGrps && filteredGrps.length > 0) {
      setAccGrps(filteredGrps);
    } else {
      type == "credit"
        ? setCustomErrors({
            ...customErrors,
            creditAccounts: "No accounts found"
          })
        : setCustomErrors({
            ...customErrors,
            debitAccounts: "No accounts found"
          });
    }
  };

  const handleKeyPress = (e, type) => {
    if (e.target.value) {
      filterAccountGroups(e.target.value, type);
    }
  };

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // Debounced event handler
  const debounceData = debounce(async (event, value) => {
    const payload = { siteId: null, searchField: event.target.value };

    if (event.target.value) {
      const valString = event.target.value;
      if (valString.length > 2) {
        setLoading(true);
        try {
          const encryptedPayload = getEncryptPayload(payload, true);

          const res = await userService.postData(
            accountGroupDropdownUrlVoucher,
            encryptedPayload
          );

          if (res?.data?.status === 200) {
            const { data } = res.data;

            const filteredAccgroup = (data || []).filter(
              (val) => val.siteId != null
            );

            const arrayOfGroup = filteredAccgroup
              .map((group) => {
                const siteMatch = siteList.find(
                  (site) => site.id === group.siteId
                );
                if (siteMatch) {
                  return {
                    ...group,
                    siteName: siteMatch.name
                  };
                }
                return null;
              })
              .filter(Boolean);

            setAccountGroupsv2(arrayOfGroup);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
            setLoading(false);
          } else {
            setLoading(false);
            setAccountGroupsv2([]);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } else {
      // setAccountGroups([]);
      // setDebitAccounts([]);
      // setCreditAccounts([]);
    }
  }, 300); // Adjust debounce time as needed

  const getPermissionCombinationList = async (url) => {
    const payload = {
      permissionId:
        endPoints && endPoints.find((val) => val.apiEndpoint === `/${url}`)?.id,
      permissionStateId: null,
      isActive: true,
      roleId: null
      // rolePermission && rolePermission.length > 0
      //   ? rolePermission.map((ele) => ele?.roleId)
      //   : []
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const res = await userService.getPermissionCombination(
        getAllState,
        encryptedPayload
      );
      if (res && res.data && res.data.status) {
        let data = res?.data?.data.map((val) => ({
          ...val,
          stateId: val?.stateId?.id,
          name: val?.stateId?.name
        }));
        setStateActionList(data);
        if (data && data.length > 0) {
          setNextState(data[0]);
        }
      }
    } catch (error) {}
  };

  const copyData = JSON.parse(JSON.stringify(debitAccounts));
  //   const [debitAccounts, setDebitAccounts] = useState([]);
  //   const [creditAccounts, setCreditAccounts] = useState([]);
  // const [searchDebitAccounts, setSearchDebitAccounts] = useState([]);
  // const [searchCreditAccounts, setSearchCreditAccounts] = useState([]);
  const localSearchHandle = (e, name) => {
    if (name === "Debit") {
      const tempData = debitAccounts?.map((ele, i) => {
        const jointValue = ele?.name + ele?.siteName;
        return {
          ...ele,
          searchValues: jointValue.toLowerCase()
        };
      });
      const val = e.target.value.toLowerCase();
      const filteredData = tempData?.filter((ele) =>
        ele.searchValues.includes(val)
      );
      setSearchDebitAccounts(filteredData);
    } else {
      const tempData = creditAccounts?.map((ele, i) => {
        const jointValue = ele?.name + ele?.siteName;
        return {
          ...ele,
          searchValues: jointValue.toLowerCase()
        };
      });
      const val = e.target.value.toLowerCase();
      const filteredData = tempData?.filter((ele) =>
        ele.searchValues.includes(val)
      );
      setSearchCreditAccounts(filteredData);
    }
  };

  useEffect(() => {
    getPermissionCombinationList(addTransactionUrl);
  }, []);

  const getAccountTypes = async () => {
    const permissionExist = checkPermissionExist(endPoints, accountTypesV1Url);
    // const isPath = checkPathExist(endPoints, window.location.pathname);
    if (permissionExist) {
      let payload = { isChildAccountCreationNeeded: true };
      let encryptedPayload = getEncryptPayload(payload, true);
      dispatch(getAllAccountTypes(encryptedPayload));
    } else {
      errorAlert("Permission Denied for account type");
    }
  };

  const addSourceAccountChildHandle = () => {
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      true,
      sourceAccountType,
      sourceAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // Check if an item with the same name or suffixName already exists

      // If no such item exists, add the new item
      const obj = {
        type: sourceAccountType,
        child: sourceAccountTypeChildList,
        id: uuidv4(),
        isNewData: true,
        deletedData: false,
        locally: true,
        isActive: true
      };
      setSourceAccountChildTableList([...sourceAccountChildTableList, obj]);
      const removeFromList =
        accountTypes &&
        accountTypes.length > 0 &&
        accountTypes.filter((ele) => ele.id !== sourceAccountType.id);
      setAccountTypes(removeFromList);
      setSourceAccountType(null);
      setSourceAccountTypeChildList(null);
    }
  };

  const deleteSourceAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setAccountTypes([...accountTypes, findDeletedData]);
      const findDeletedDataList = sourceAccountChildTableList?.map((ele) => {
        return {
          ...ele,
          isActive: ele.id === id?.id ? false : ele?.isActive,
          deletedData: ele.id === id?.id ? true : ele?.deletedData
        };
      });
      setSourceAccountChildTableList(findDeletedDataList);
    }
  };

  const addTargetAccountChildHandle = () => {
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      false,
      targetAccountType,
      targetAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // If no such item exists, add the new item
      const obj = {
        type: targetAccountType,
        child: targetAccountTypeChildList,
        id: uuidv4(),
        isNewData: true,
        deletedData: false,
        locally: true,
        isActive: true
      };
      setTargetAccountChildTableList([...targetAccountChildTableList, obj]);
      const removeFromList =
        targetAccountTypes &&
        targetAccountTypes.length > 0 &&
        targetAccountTypes.filter((ele) => ele.id !== targetAccountType.id);
      setTargetAccountTypes(removeFromList);
      setTargetAccountType(null);
      setTargetAccountTypeChildList(null);
    }
  };
  const deleteTargetAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setTargetAccountTypes([...targetAccountTypes, findDeletedData]);
      const findDeletedDataList = targetAccountChildTableList?.map((ele) => {
        return {
          ...ele,
          isActive: ele.id === id?.id ? false : ele?.isActive,
          deletedData: ele.id === id?.id ? true : ele?.deletedData
        };
      });
      setTargetAccountChildTableList(findDeletedDataList);
    }
  };

  // useEffect(() => {
  //   setAccountTypes(accountTypesState?.data);
  //   setTargetAccountTypes(accountTypesState?.data);
  // }, [accountTypesState]);

  // useEffect(() => {
  //   setAccountTypes(accountTypesState?.data);
  //   setTargetAccountTypes(accountTypesState?.data);
  //   console.log(targetExistData, targetAccountTypes, sourceExistData, accountTypes);
  //   const filteredArray = mainArray.filter(item => idArray.includes(item.id));
  // }, [accountTypesState, targetExistData]);

  useEffect(() => {
    const nextState =
      stateActionList.find((val) => val.stateId == permissionStateId) || null;
    setNextState(nextState);
  }, [permissionStateId, stateActionList]);

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={0}
        style={{ width: "100%", overflow: "auto" }}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="12"
              source={customFieldIcon}
              headerText={`Update Voucher`}
            />
          </Grid>

          {/* ============Voucher Temporary Fields============ */}
          <Grid container marginTop={2} justifyContent="space-between">
            <Grid item xs={4}>
              <TextField
                label="Voucher"
                style={{ width: "300px" }}
                variant="outlined"
                size="small"
                margin="dense"
                value={tempateName || ""}
                disabled
                onChange={(e) => setTemplateName(e.target.value)}
                error={customErrors.tempateName}
                onBlur={() => {
                  setTemplateName(tempateName.replace(/\s+/g, " ").trim());
                  tempateName
                    ? setCustomErrors({ ...customErrors, tempateName: false })
                    : setCustomErrors({ ...customErrors, tempateName: true });
                }}
                onInput={(e) =>
                  (e.target.value = e.target.value.toString().slice(0, 20))
                }
              />
            </Grid>

            <Grid item xs={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <Autocomplete
                  options={
                    (accountGroupsv2 &&
                      accountGroupsv2.length > 0 &&
                      accountGroupsv2.filter(
                        (option) =>
                          !debitAccounts.some(
                            (selectedOption) => selectedOption.id == option.id
                          )
                      )) ||
                    []
                  }
                  //   options={
                  //     (accGrps &&
                  //       accGrps.length > 0 &&
                  //       accGrps.filter(
                  //         (option) =>
                  //           !debitAccounts.some(
                  //             (selectedOption) => selectedOption.id == option.id
                  //           )
                  //       )) ||
                  //     []
                  //   }
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  // getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "debit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Debit Group"
                      size="small"
                      variant="outlined"
                      error={customErrors.debitAccounts}
                      onBlur={() => {
                        debitAccounts && debitAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              debitAccounts: false
                            })
                          : setCustomErrors({
                              ...customErrors,
                              debitAccounts: true
                            });
                      }}
                      onChange={debounceData}
                      //   onKeyPress={(e) => handleKeyPress(e, "debit")}
                    />
                  )}
                />
                {/* <IconButton onClick={() => handleClick("debit")}>
                  <FcSettings />
                </IconButton> */}
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                
              </div> */}
            </Grid>

            <Grid item xs={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <Autocomplete
                  // options={(accountGroups && accountGroups.length > 0 && accountGroups.filter(val => val.name !== "All")) || []}
                  //   options={
                  //     (accGrps &&
                  //       accGrps.length > 0 &&
                  //       accGrps.filter(
                  //         (option) =>
                  //           !creditAccounts.some(
                  //             (selectedOption) => selectedOption.id == option.id
                  //           )
                  //       )) ||
                  //     []
                  //   }
                  options={
                    (accountGroupsv2 &&
                      accountGroupsv2.length > 0 &&
                      accountGroupsv2.filter(
                        (option) =>
                          !creditAccounts.some(
                            (selectedOption) => selectedOption.id == option.id
                          )
                      )) ||
                    []
                  }
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  // getOptionLabel={(option) => option?.name || ""}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  // onChange={(event, newValue) => setCreditAccounts(newValue)}
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "credit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Credit Groups"
                      size="small"
                      variant="outlined"
                      error={customErrors.creditAccounts}
                      onBlur={() => {
                        creditAccounts && creditAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              creditAccounts: false
                            })
                          : setCustomErrors({
                              ...customErrors,
                              creditAccounts: true
                            });
                      }}
                      onChange={debounceData}
                      //   onKeyPress={(e) => handleKeyPress(e, "credit")}
                    />
                  )}
                />
                {/* <IconButton onClick={() => handleClick("credit")}>
                                    <FcSettings />
                                </IconButton> */}
              </div>
            </Grid>

            <Grid item xs={4} marginTop={2}>
              <Autocomplete
                options={stateActionList || []}
                value={nextState}
                size="small"
                fullWidth
                getOptionLabel={(option) => `${option?.name}` || ""}
                getOptionSelected={(option, value) =>
                  option?.name === value?.name
                }
                onChange={(event, newValue) => {
                  newValue && setNextState(newValue);
                }}
                style={{ width: "300px" }}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Current State"
                    size="small"
                    required
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <Box sx={{ marginTop: "9px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <div
                      className="field_box calender"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        name="search"
                        type="text"
                        style={{ width: "300px" }}
                        size="small"
                        variant="outlined"
                        placeholder="Search"
                        fullWidth
                        onChange={(e) => localSearchHandle(e, "Debit")}
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              sx={{ cursor: "pointer" }}
                              color="action"
                              position="end"
                            />
                          )
                        }}
                      />
                      <Tooltip
                        title={<Typography>Clear All</Typography>}
                        placement="bottom-start"
                      >
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={() => {
                            setDebitAccounts([]);
                            setSearchDebitAccounts([]);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        height: "400px",
                        "& ::-webkit-scrollbar": {
                          width: "4px"
                        },
                        "& ::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          backgroundColor: "darkgrey"
                        }
                      }}
                    >
                      <AutoSizer>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            searchDebitAccounts &&
                            searchDebitAccounts.length > 0 && (
                              <List
                                width={335}
                                height={height}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  searchDebitAccounts?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    searchDebitAccounts?.length
                                  );
                                  const items = searchDebitAccounts
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Tooltip
                                          title={`${val.name} (${
                                            val?.siteName ? val?.siteName : ""
                                          })`}
                                        >
                                          <Button
                                            // disabled
                                            size="small"
                                            className="account_group_btn"
                                            sx={{
                                              marginLeft: 1,
                                              marginY: "5px",
                                              background: "green",
                                              color: "#fff !important",
                                              pointerEvents: "all !important",
                                              ":hover": {
                                                background: "green"
                                              }
                                            }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              }}
                                            >
                                              {val.name}{" "}
                                              {val?.siteName &&
                                                `(${val?.siteName})`}
                                            </p>
                                          </Button>
                                        </Tooltip>
                                        <ClearIcon
                                          sx={{
                                            fontSize: 16,
                                            color: "red",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            const accounts = [...debitAccounts];
                                            const accountsFilter =
                                              accounts.filter(
                                                (ele, i) => ele.id !== val.id
                                              );
                                            // accounts.splice(ind, 1);
                                            setDebitAccounts(accountsFilter);
                                            setSearchDebitAccounts(
                                              accountsFilter
                                            );
                                          }}
                                        />
                                      </div>
                                    ));

                                  return (
                                    // items.length > 0 && (

                                    <Grid item md={12} key={key} style={style}>
                                      {items}
                                    </Grid>
                                    // )
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box sx={{ marginTop: "9px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <div
                      className="field_box calender"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        name="search"
                        type="text"
                        style={{ width: "300px" }}
                        size="small"
                        variant="outlined"
                        placeholder="Search"
                        fullWidth
                        onChange={(e) => localSearchHandle(e, "Credit")}
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              sx={{ cursor: "pointer" }}
                              color="action"
                              position="end"
                            />
                          )
                        }}
                      />
                      <Tooltip
                        title={<Typography>Clear All</Typography>}
                        placement="bottom-start"
                      >
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={() => {
                            setCreditAccounts([]);
                            setSearchCreditAccounts([]);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        height: "400px",
                        "& ::-webkit-scrollbar": {
                          width: "4px"
                        },
                        "& ::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          backgroundColor: "darkgrey"
                        }
                      }}
                    >
                      <AutoSizer>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            creditAccounts &&
                            creditAccounts.length > 0 && (
                              <List
                                width={335}
                                height={height}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  searchCreditAccounts?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    searchCreditAccounts?.length
                                  );
                                  const items = searchCreditAccounts
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Tooltip
                                          title={`${val.name} (${
                                            val?.siteName ? val?.siteName : ""
                                          })`}
                                        >
                                          <Button
                                            // disabled
                                            size="small"
                                            className="account_group_btn"
                                            sx={{
                                              marginLeft: 1,
                                              marginY: "5px",
                                              background: "#a11919",
                                              color: "#fff !important",
                                              maxWidth: "295px",
                                              pointerEvents: "all !important",
                                              ":hover": {
                                                background: "#a11919"
                                              }
                                            }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              }}
                                            >
                                              {val.name}{" "}
                                              {val?.siteName &&
                                                `(${val?.siteName})`}
                                            </p>
                                          </Button>
                                        </Tooltip>
                                        <ClearIcon
                                          sx={{
                                            fontSize: 16,
                                            color: "red",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            const accounts = [
                                              ...creditAccounts
                                            ];
                                            const accountsFilter =
                                              accounts.filter(
                                                (ele, i) => ele.id !== val.id
                                              );
                                            // accounts.splice(ind, 1);
                                            setCreditAccounts(accountsFilter);
                                            setSearchCreditAccounts(
                                              accountsFilter
                                            );
                                          }}
                                        />
                                      </div>
                                    ));

                                  return (
                                    // items.length > 0 && (

                                    <Grid item md={12} key={key} style={style}>
                                      {items}
                                    </Grid>
                                    // )
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* ==============Child Account Configuration Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ChildAccountConfiguration
              addTargetAccountChildHandle={addTargetAccountChildHandle}
              deleteTargetAccountChildHandle={deleteTargetAccountChildHandle}
              sourceAccountChildTableList={
                sourceAccountChildTableList &&
                sourceAccountChildTableList?.filter(
                  (ele, i) => ele.isActive !== false
                )
              }
              deleteSourceAccountChildHandle={deleteSourceAccountChildHandle}
              setSourceAccountChildTableList={setSourceAccountChildTableList}
              addSourceAccountChildHandle={addSourceAccountChildHandle}
              childConfigError={childConfigError}
              setChildConfigError={setChildConfigError}
              accountTypes={accountTypes || []}
              targetAccountTypes={targetAccountTypes || []}
              sourceAccountType={sourceAccountType}
              setSourceAccountType={setSourceAccountType}
              sourceAccountTypeChildList={sourceAccountTypeChildList}
              setSourceAccountTypeChildList={setSourceAccountTypeChildList}
              sourceAccountTypeChildLists={sourceAccountTypeChildLists}
              setSourceAccountTypeChildLists={setSourceAccountTypeChildLists}
              targetAccountType={targetAccountType}
              setTargetAccountType={setTargetAccountType}
              setAccountTypeChildLists={setAccountTypeChildLists}
              accountTypeChildLists={accountTypeChildLists}
              targetAccountTypeChildLists={targetAccountTypeChildLists}
              targetAccountTypeChildList={targetAccountTypeChildList}
              setTargetAccountTypeChildLists={setTargetAccountTypeChildLists}
              setTargetAccountTypeChildList={setTargetAccountTypeChildList}
              targetAccountChildTableList={
                targetAccountChildTableList &&
                targetAccountChildTableList?.filter(
                  (ele, i) => ele.isActive !== false
                )
              }
              setTargetAccountChildTableList={setTargetAccountChildTableList}
            />
          </Grid>

          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DetailsViewConfigurations
              templateViewList={templateViewList}
              selectTemplateView={selectTemplateView}
              setSelectTemplateView={setSelectTemplateView}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DueDataConfigurations
              overDueBoolean={overDueBoolean}
              setOverDueBoolean={setOverDueBoolean}
              accountCategory={accountCategory}
              setAccountCategory={setAccountCategory}
              overDueDays={overDueDays}
              setOverDueDays={setOverDueDays}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
              checkboxError={checkboxError}
              setCheckboxError={setCheckboxError}
              radioError={radioError}
              setRadioError={setRadioError}
              taggedInvoice={taggedInvoice}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <TaggedFunction
              overDueBoolean={overDueBoolean}
              taggedInvoice={taggedInvoice}
              setTaggedInvoice={setTaggedInvoice}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>

          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <MandatoryCheck
              invoiceOrNoteNumber={invoiceOrNoteNumber}
              setInvoiceorNotNumber={setInvoiceorNotNumber}
              accountCode={accountCode}
              setAccountCode={setAccountCode}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              eInvoiceGeneration={eInvoiceGeneration}
              setEInvoiceGeneration={setEInvoiceGeneration}
              eInvoiceGenerationImmediate={eInvoiceGenerationImmediate}
              setEInvoiceGenerationImmediate={setEInvoiceGenerationImmediate}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <EInvoiceDocTypes
              invDocType={invDocType}
              setInvDocType={setInvDocType}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>

          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ConfigurationCheckFields
              openModal={openModal}
              setOpenModal={setOpenModal}
              dynamicFields={dynamicFields}
              setDynamicFields={setDynamicFields}
              setHandleTableComponent={setHandleTableComponent}
              openEditorFuntion={openEditorFuntion}
            />
          </Grid>

          {/* <Grid container marginTop={2} justifyContent="space-between">
                        <Grid item xs={3}>
                            <Autocomplete

                                options={nextActionList || []}
                                value={nextState || ""}
                                size="small"
                                style={{ width: "100%" }}
                                getOptionLabel={(option) => option?.name || ""}
                                getOptionSelected={(option, value) => option.name == value.name}
                                onChange={(event, newValue) => {
                                    setNextState(newValue)
                                }}
                                variant="outlined"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Next State"
                                        size="small"
                                        variant="outlined"
                                        error={customErrors.nextState}
                                        onBlur={() => {
                                            nextState && nextState.length > 0
                                                ? setCustomErrors({ ...customErrors, nextState: false })
                                                : setCustomErrors({ ...customErrors, nextState: true });
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                    </Grid> */}

          {/* ============Voucher Static Fields============ */}
          {/* <Grid container marginTop={2}>
                        <Grid item xs={12}>
                            <u>
                                <Typography variant="h6">Static Fields</Typography>
                            </u>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Source Account"
                                style={{ width: 300 }}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                disabled

                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Target Account"
                                style={{ width: 300 }}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                disabled

                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label=" Amount"
                                style={{ width: 300 }}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                disabled

                            />


                        </Grid>


                    </Grid> */}

          {/* ============Voucher Dynamic Fields============ */}
          {/* <Grid container marginTop={2} marginBottom={2}>
            <Grid item xs={12} marginY={3}>
              <Typography variant="h6">
                <u>Configure Voucher Fields</u>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <DragAndDropComponent
                openModal={openModal}
                setOpenModal={setOpenModal}
                dynamicFields={dynamicFields}
                setDynamicFields={setDynamicFields}
                setHandleTableComponent={setHandleTableComponent}
                openEditorFuntion={openEditorFuntion}
              />
            </Grid>
          </Grid> */}

          {/* ================Buttons============ */}

          <Grid
            container
            justifyContent="flex-end"
            style={{ margin: "20px 0px", padding: "10px 0px" }}
          >
            <Grid item>
              <Button
                color="primary"
                style={{
                  background: `${themecolor}`,
                  display: checkPermissionExist(endPoints, voucherUdpateUrl)
                    ? "inline-flex"
                    : "none"
                }}
                variant="contained"
                onClick={() => handleUpdate()}
              >
                Update{" "}
              </Button>
            </Grid>
            <Grid item marginLeft={2}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      {/* =========Table Component Modal=========== */}
      {openModal && (
        <TableCompoentModal
          open={openModal}
          closeDialogBox={closeDialogBox}
          tableItem={tableItem}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          errorAlert={errorAlert}
        />
      )}

      {/* ======================Editor Modal============ */}
      {openEditor && (
        <Editor
          open={openEditor}
          closeDialogBox={closeEditor}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          editedIndex={editedIndex}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          errorAlert={errorAlert}
        />
      )}
      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* ============Config Modal============= */}
      <ConfigModal
        open={openConfigModal}
        closeDialogBox={closeConfigModal}
        accounts={accounts}
        handlAdd={handlAdd}
        handleAccounts={handleAccounts}
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        pageSize={pageSize}
        setPageSize={setPageSize}
        getAccountList={getAccountList}
        debitAccounts={debitAccounts}
      />

      {/* =============Loader============= */}
      {loading && <LoadingComponent />}
    </>
  );
};

export default withStyles(styles)(EditTemplate);
